import React from 'react';
import PropTypes from 'prop-types';
import '../../css/zadosti.css';
import DokumentaceVersionsOlder from './DokumentaceVersionsOlder.jsx';
import DokumentaceVersionsNewest from './DokumentaceVersionsNewest.jsx';
import { infoCircle } from '../../assets/index.js';

const DokumentaceDetailMainSection = ({item}) => {
    const { versions, zamerDetail } = item || {};
    const mappedVersions = [...(versions || []).map(el => ({...(zamerDetail || {}), ...el}))];
    console.log('mapped versions', mappedVersions);
    // versionNumber or date
    // for versionNumber
    // const sortedVersions = [...(mappedVersions || [])].sort((a, b) => Number(a?.versionNumber || 0) < Number(b?.versionNumber || 0) ? 1 : -1);
    // for finishDate
    const sortedVersions = [...(mappedVersions || [])].sort((a, b) => b.versionNumber - a.versionNumber);
    console.log('sorted versions', sortedVersions);
    
    const newest = sortedVersions?.[0] ?? {}; // obj{}
    const older = sortedVersions?.slice(1) ?? []; // arr[]

    console.log('ITEM', item);

    return (
        <div className='my-4'>
            {mappedVersions?.length > 1 && (
                <div className='d-flex kontrola-info my-2'>
                    <img src={infoCircle} alt="infoCircle" className='me-2' />
                    Pozor, ve spisu dokumentace záměru je vloženo více verzí dokumentace. Níže zobrazujeme všechny vložené verze dokumentace, včetně té nejnovější, která je označena jako nejnovější a je barevně odlišena.
                </div>
            )}

            {mappedVersions?.length > 0 && (
                <div>
                    <DokumentaceVersionsNewest item={newest} />
                    <DokumentaceVersionsOlder items={older} />
                </div>
            )}
        </div>
    );
};

DokumentaceDetailMainSection.propTypes = {
    item: PropTypes.any
};

export default DokumentaceDetailMainSection;
