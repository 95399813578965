import React from 'react';
import { Button } from './index.js';
import { stop } from '../assets/index.js';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const ErrorPage = ({ errorName = 'Chyba aplikace', idFromBoundary = null, isFromErrorBoundary = false, customComponent = <div></div>, errorCode = 500, navigateTo = '/'}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname, id } = location?.state || {};
    const { pathname: currentFormPathName } = location || {};
    
    const handleNavigate = () => {
        if (isFromErrorBoundary) {
            const currentPathWithId = /^\/zadost\/form[0-9]{2}\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/.exec(currentFormPathName)?.[0] ?? '';
            const currentPathWithoutId = /^\/zadost\/form[0-9]{2}$/.exec(currentFormPathName)?.[0] ?? '';
            if (currentPathWithId) {
                window.location.reload(); 
            } else if (currentPathWithoutId) {
                if (idFromBoundary) {
                    navigate(`${currentPathWithoutId}/${idFromBoundary}`);
                } else {
                    navigate(`${currentPathWithoutId}`);
                }
            } else {
                navigate('/');
            }
            return;
        }

        if (!id || !pathname) {
            navigate(navigateTo);
            return;
        }

        const urlHasId = pathname?.match(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/g);

        if (Array.isArray(urlHasId) && urlHasId[0] === id) {
            navigate(`${pathname}`);
        } else {
            navigate(`${pathname}/${id}`);
        }
    };

    return(
        <div className='h-100 d-flex flex-column justify-content-center align-items-center p-3 my-5'>
            <img className='error-page--img' src={stop} alt='something went wrong'/>
            <p className='error-page--header-text'>{errorName}</p>
            <>
                {customComponent}
            </>
            <div className='d-flex justify-content-center mb-2'>
                <Button 
                    onClickFunction={handleNavigate} 
                    buttonLabel={'Obnovit stránku'}
                />
            </div>
            <i>{errorCode}</i>
        </div>
    );
};

ErrorPage.propTypes = {
    idFromBoundary: PropTypes.string,
    isFromErrorBoundary: PropTypes.bool
};

export default ErrorPage;