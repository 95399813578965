import React, { useState, useContext, useEffect, useRef, useImperativeHandle } from 'react';
import { AuthContext } from 'react-oauth2-code-pkce';
import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap';
import '../../css/zadostPovoleniStavby.css';
import '../../css/zamerComponent.css';
import { errorIcon, infoCircle } from '../../assets/index.js';
import PropTypes from 'prop-types';
import { formSave, getFormById } from '../../apiCalls/formApiCalls';
import { StavbyTable, PozemkyTable, FormInput, NewConstructionModal, ZamerSelectionControls, VodniDilo } from '../../components/index.js';
import { BUILDING_ADDRESS } from '../../constants/form10.js';
import FormContext from '../../formContexts/FormContext.js';
import { shouldNotSave, spreadParcelsAndConstructions, spreadObject, checkLimitAndInsertText, hasZamerError, useOnlyRequiredKeys } from '../../helperFunctions/helpers.js';
import { dateValidation } from '../../helperFunctions/validationHelpers.js';
import usePrevious from '../CustomHooks/usePrevious.jsx';
import { handleClose, handleShow } from '../../helperFunctions/modalHelpers.js';
import { AUTHORITY_MODEL } from '../../constants/sharedConstants.js';
import { CharCount } from '../../components/index.js';
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

const buildIntentionModel = {
    title: { value: null, dirty: false },
    newBuilding: false,
    buildingTypePurpose: '',
    buildingTemporary: false,
    waterworks: false,
    buildingTemporaryDuration: '',
    waterworksPurpose: '',
    watercourseName: '',
    watercourseId: '',
    hydrogeologicalAreaName: '',
    hydrogeologicalAreaID: '',
    permissionRefNumber: '',
    constructionIntentionId: '',
    protectZoneDetermination: false,
    permissionAuthority: AUTHORITY_MODEL,
};

const ZamerComponent10 = ({
    urlPath
}) => {
    const {intention, stepValue, validationRef, setStagesArr, stagesArr, saveFormRef, id} = useContext(FormContext); 
    
    const [show, setShow] = useState(false);
    const [newBuilding, setNewBuilding] = useState({
        isChecked: false,
        select: '',
    });
    const [request, setRequest] = useState({
        'applicationId': id,
        form: {
            id: null,
            constructionName: '',
            protectionZoneCharacteristics: '',
            proposalProhibitionsRestrictions: '',   
            constructionType: '',
            estimatedDurationDate: '',
            estimatedDurationEntire: false,
        },
        buildIntention: buildIntentionModel
    });

    const [isIntentionCheckboxDisabled, setIsIntentionCheckboxDisabled] = useState(false);
    const [count, setCount] = useState({
        [0]: {count: 0, limit: 10000},
        [1]: {count: 0, limit: 10000},
    });
    const [ focused, setFocused ] = useState({
        buildingTemporaryDuration: false,
        waterworksPurpose: false,
        watercourseName: false,
        watercourseId: false,
        constructionName: false,
        permissionRefNumber: false,
        protectionZoneCharacteristics: false,
        proposalProhibitionsRestrictions: false,
        estimatedDurationDate: false
    });   
    const handleFocused = (elementToFocus) => {
        setFocused(prev => ({...prev, [elementToFocus]: true}));
    };
    const [isExceeded, setIsExceeded] = useState(false);
    const { token } = useContext(AuthContext);
    const initialRender = useRef(true);
    const [state, setState] = useState({
        inputValue: '',
        zamerType: 'new',
    });
    const error = stagesArr.find(stg => Number(stg.stage) === Number(stepValue))?.error ?? false;
    const [isDateError, setIsDateError] = useState({
        protectiveZoneDate: false,
    });

    const [loading, setLoading] = useState(false);
    const [parcelAndConstructionData, setParcelAndConstructionData] = useState({
        approvedConstructions: [],
        buildParcels: [],
    });

    const prevRequest = usePrevious(request);

    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const source = axios.CancelToken.source();
        if (id) {
            (async () => {
                const response = await getFormById(id, token, source, intention, null, pathname, navigate);
    
                if ((200 <= response?.status) && (response?.status < 300)) {
                    spreadParcelsAndConstructions(
                        response.data, //obj
                        setParcelAndConstructionData, //func
                        parcelAndConstructionData // obj                
                    );

                    // do not send id: null if a form is present
                    const formData = response.data?.buildApplicationForms?.[0];
                    if (formData) {
                        const isCopied = response.data?.buildApplication?.isCopied || false;
                        setIsIntentionCheckboxDisabled(isCopied);
                        setNewBuilding(state => ({...state, select: formData.constructionType}));
                    }
                    
                    const intention = response.data?.buildIntention;
                    const formStructure = request.form;
                    const filteredDataToSave = useOnlyRequiredKeys(formStructure, formData);
                    if (intention) {
                        // eslint-disable-next-line no-unused-vars
                        const { buildConstructions, buildParcels, affectedBuildConstructions, affectedBuildParcels, approvedConstructions, ...rest } = intention;
                        setRequest(state => ({ ...state, buildIntention: spreadObject(rest), ...((formData && formData.id) ? { form: { ...state.form, ...filteredDataToSave, ...formData } } : {}) }));      
                        setState(prevSate => ({ 
                            ...prevSate,
                            inputValue: (intention.icsId || intention.title.value) ? (intention.icsId ?? '') + ((intention.icsId && intention.title.value) ? ' - ' : '') + (intention.title.value ?? '') : '',
                            zamerType: intention.eszId ? 'existing' : 'new' 
                        }));
                    }
                    setLoading(false);
                }
            })();

            return () => {
                source.cancel('Operation canceled by the user.');
            };
        }
        else {
            console.log('NON EXISTENT ID');
            setLoading(false);
        }
    }, []);

    const saveChanges = async () => {
        const source = axios.CancelToken.source();
        await formSave(request, token, urlPath, source);
    };

    const buildIntention = request?.buildIntention;
    const form = request?.form;

    useEffect(() => {
        if (
            (buildIntention?.buildParcels) ||
            (buildIntention?.buildConstructions)
        ) {
            setParcelAndConstructionData(prev => ({
                ...prev,
                buildParcels: [...(buildIntention?.buildParcels ?? [])].map((item) => ({...item, uid: uuidv4()})),
                approvedConstructions: [...(buildIntention?.buildConstructions ?? [])].map((item) => ({...item, uid: uuidv4()})),
            }));
            setRequest(prev => ({...prev, approvedConstructions: buildIntention?.buildConstructions, buildParcels: buildIntention?.buildParcels}));
        }
        
        if (initialRender.current) {
            initialRender.current = false;
        } else {
            // avoid multiple save for same object
            if (shouldNotSave(prevRequest, request)) {
                return;
            }

            if (buildIntention?.title?.value === null) {
                return;
            }

            saveChanges();
        }
    }, [buildIntention, form]);

    useImperativeHandle(saveFormRef, () => ({
        saveForm: () => saveChanges()
    }));

    useImperativeHandle(validationRef, () => ({
        validate() {
            let err = false;

            if (hasZamerError(buildIntention)) {
                err = true;
            } else if (!request?.form?.constructionName?.trim())
            {
                err = true;
            }
            else if (!request?.form?.constructionType?.trim())
            {
                err = true;
            }
            else if(buildIntention?.buildingTemporary &&
                !buildIntention?.buildingTemporaryDuration?.trim())
            {
                err = true;
            }
            else if (buildIntention?.waterworks && !buildIntention?.waterworksPurpose)
            {
                err = true;
            }
            else if (!request?.form?.proposalProhibitionsRestrictions?.trim() ||
                !request?.form?.protectionZoneCharacteristics?.trim())
            {
                err = true;
            }
            else if (request?.buildIntention?.protectZoneDetermination &&
                (!buildIntention?.permissionRefNumber?.trim() ||
                !buildIntention?.permissionAuthority?.authorityName?.trim()))
            {
                err = true;
            }
            else if (isDateError.protectiveZoneDate) {
                err = true;
            }
            else if(!request?.form?.estimatedDurationEntire && 
                !request?.form?.estimatedDurationDate?.trim())
            {
                err = true;
            }

            

            setStagesArr(prev => [...prev].map(stg => {
                if (Number(stg.stage) === Number(stepValue)) {
                    
                    return {...stg, error: err ?? false, };
                }
                return stg;
            }));

            return !err;
        }
    }));

    const constructionNameError = !request?.form?.constructionName?.trim() && (error || focused.constructionName);
    const buildingTemporaryDurationError = buildIntention.buildingTemporary && !buildIntention?.buildingTemporaryDuration?.trim() && (error || focused.buildingTemporaryDuration);
    const permissionRefNumberError = !buildIntention?.permissionRefNumber?.trim() && (error || focused.permissionRefNumber);
    const protectionZoneCharacteristicsError = !request?.form?.protectionZoneCharacteristics?.trim() && (error || focused.protectionZoneCharacteristics);
    const proposalProhibitionsRestrictionsError = !request?.form?.proposalProhibitionsRestrictions?.trim() && (error || focused.proposalProhibitionsRestrictions);
    const estimatedDurationDateError = !request?.form?.estimatedDurationEntire && !request?.form?.estimatedDurationDate?.trim() && (error || focused.estimatedDurationDate);

    return (
        <>
            {loading ? <div className='loading'></div> :
                <Container fluid>
                    <Row className='row-wrapper'>
                        <h2 className='p-0 m-0 main-title'>{`${stepValue}. Záměr`}</h2>
                    </Row>

                    <ZamerSelectionControls
                        setNewBuilding={setNewBuilding}
                        setParcelAndConstructionData={setParcelAndConstructionData}
                        parcelAndConstructionData={parcelAndConstructionData}
                        request={request}
                        setRequest={setRequest}
                        state={state}
                        setState={setState}
                        buildIntentionModel={buildIntentionModel}
                        textExistujici={'existující záměr na stanovení ochranného pásma'}
                        textNovy={'nový záměr na stanovení ochranného pásma'}
                        isIntentionCheckboxDisabled={isIntentionCheckboxDisabled}
                    />

                    <Row className='mb-4'>
                        <p className='p-0 section-title'>Základní údaje o stavbě, pro kterou se ochranné pásmo stanoví</p>
                    </Row>

                    <Row>
                        <Col md={6}>
                            <label className='p-0 d-flex flex-column povoleni-label' style={{ color: constructionNameError ? '#C52A3A' : '' }}>
                                Název stavby
                                <input 
                                    type='text'
                                    defaultValue={request?.form?.constructionName ?? ''}

                                    style={{ border: constructionNameError && 'solid 1px #C52A3A'}} 
                                    onBlur={(e) => {setRequest(state => ({ ...state, form: { ...state.form, constructionName: e.target.value } }));
                                        handleFocused('constructionName');
                                    }}
                                    className='povoleni-input bg-white p-2 mt-1'
                                />
                            </label>
                        </Col>
                    </Row>
                    {constructionNameError && <Col md={{span: 6}} className='mt-2'>
                        <p className='d-flex align-items-center p-0 warning-text'><img src={errorIcon} alt='warning' className='me-2' style={{ height: '1.1rem', width: '1.1rem' }} />Bez vyplnění pole “Název stavby” není možné pokračovat.</p>
                    </Col>}

                    <Row className='row-wrapper mt-4'>
                        <Col md={6}>
                            <p style={{color: error && !request?.form?.constructionType?.trim() ? '#C52A3A': ''}} className='p-0 mb-1 new-construction-help-text'>Druh a účel stavby</p>
                            
                            <label className='id-checkbox-label w-100'>
                                <input type="text" value={newBuilding?.select ?? ''} className='new-construction-input' disabled/>
                                <button 
                                    type='button'
                                    className='d-flex justify-content-center align-items-center new-construction-button' 
                                    onClick={() => handleShow(setShow)}
                                    style={{ border: error && !request?.form?.constructionType?.trim() && 'solid 1px #C52A3A'}} 
                                >
                                    Vybrat
                                </button>
                            </label>
                        </Col>
                        <NewConstructionModal
                            show={show} 
                            setShow={setShow}
                            handleClose={handleClose}
                            setNewBuilding={setNewBuilding} 
                            setRequest={setRequest}
                            object={'form'}
                            propertyToUpdate={'constructionType'}
                        />
                    </Row>

                    {/* Místo záměru */}
                    <Row className='row-wrapper border p-4'>
                        <p className='section-title p-0 mb-4'>Místo stavby</p>
                        <form className='p-0'>
                            {BUILDING_ADDRESS.map(item => (
                                <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />
                            ))}
                        </form>
                    </Row>

                    <label className='mb-2 id-checkbox-label'>
                        <input type="checkbox" className='id-checkbox-input' checked={Boolean(buildIntention.buildingTemporary)}
                            onChange={(e) => { setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, buildingTemporary: e.target.checked } })); }} />
                                Stavba dočasná
                    </label>

                    {buildIntention.buildingTemporary
                        &&
                    <div className='mb-4'>
                        <Row className='d-flex'>
                            <Col xs={12} md={6} className='d-flex flex-column'>
                                <label className='select-input' style={{color: buildingTemporaryDurationError ? '#C52A3A': ''}}>
                                Doba trvání dočasné stavby
                                    <input
                                        type='text'
                                        defaultValue={buildIntention?.buildingTemporaryDuration ?? ''}
                                        style={{border: buildingTemporaryDurationError ? 'solid 1px #C52A3A' : ''}}
                                        required
                                        onBlur={(e) => {
                                            setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, buildingTemporaryDuration: e.target.value } }));
                                            handleFocused('buildingTemporaryDuration');
                                        }}
                                        className='mt-1 id-input2 waterworks bg-white p-2'
                                    />
                                </label>
                            </Col>
                        </Row>
                        {buildingTemporaryDurationError && <Col xs={{ span: 6}} className='mt-2'>
                            <p className='d-flex align-items-center p-0 warning-text'><img src={errorIcon} alt='warning' className='me-2' style={{ height: '1.1rem', width: '1.1rem' }} />Bez vyplnění pole “Doba trvání dočasné stavby” není možné pokračovat.</p>
                        </Col>}
                    </div>
                    
                    }

                    <Row className='row-wrapper'>
                        <label className='p-0 id-checkbox-label'>
                            <input type="checkbox" className='id-checkbox-input' checked={Boolean(buildIntention.waterworks)}
                                onChange={(e) => { setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, waterworks: e.target.checked } })); }} />
                                Vodní dílo
                        </label>
                            
                        {buildIntention.waterworks &&
                            <VodniDilo
                                buildIntention={buildIntention}
                                focused={focused}
                                request={request}
                                setRequest={setRequest}
                                handleFocused={handleFocused}
                            />}
                    </Row>

                    <Row className='row-wrapper'>
                        <p className='p-0 mb-4 zamer-section__title'>Řízení o povolení stavby</p>
                        <Col md={12}>
                            <label className='p-0 id-checkbox-label'>
                                <input
                                    type='checkbox'
                                    className='id-checkbox-input'
                                    checked={request?.buildIntention?.protectZoneDetermination}
                                    onChange={(e) => {
                                        setRequest(state => ({...state, buildIntention: {...state.buildIntention, protectZoneDetermination: e.target.checked}}));
                                    }}
                                />
                                O stavbě, pro kterou se navrhuje stanovení ochranného pásma, je vedeno řízení o jejím povolení 
                            </label>
                        </Col>
                    </Row>

                    {request?.buildIntention?.protectZoneDetermination && <>
                        {/* Označení správního orgánu, který vede řízení */}
                        <FormInput
                            type='text'
                            request={request}
                            setRequest={setRequest}
                            id={104}
                            required={true}
                            label={'Označení správního orgánu, který vede řízení'}
                            object={'buildIntention'}
                            propertyToUpdate={'permissionAuthority'}
                            state={'authorityName'}
                            refer={'buildIntention-permissionAuthority'}
                        />
                        <Row className='d-flex flex-column row-wrapper mt-2'>
                            <label className='py-2 px-0 label'>
                                <Col md={6} style={{color: permissionRefNumberError ? '#C52A3A': ''}}>
                                    Číslo jednací
                                    <input 
                                        type='text'
                                        defaultValue={buildIntention?.permissionRefNumber ?? ''}
                                        style={{border: permissionRefNumberError ? '#C52A3A solid 1px' : ''}}
                                        onBlur={(e) => {setRequest(state => ({ ...state, buildIntention: { ...state?.buildIntention, permissionRefNumber: e.target.value } }));
                                            handleFocused('permissionRefNumber');
                                        }}
                                        className='mt-1 form-zamer-input waterworks bg-white p-2'
                                    />
                                </Col>
                                {permissionRefNumberError && <Col md={{ span: 6}} className='mt-2'>
                                    <p className='d-flex align-items-center p-0 warning-text'><img src={errorIcon} alt='warning' className='me-2' style={{ height: '1.1rem', width: '1.1rem' }} />Bez vyplnění pole “Číslo jednací” není možné pokračovat.</p>
                                </Col>}
                            </label>
                            <label className='py-2 px-0 label'>
                                <Row className='d-flex align-items-end'>
                                    <Col className='mb-md-0' md={6}>
                                    ID záměru stavby
                                        <input 
                                            type='text'
                                            defaultValue={buildIntention?.constructionIntentionId ?? ''}
                                            onBlur={(e) => {setRequest(state => ({ ...state, buildIntention: { ...state?.buildIntention, constructionIntentionId: e.target.value } }));
                                            }}
                                            className='mt-1 form-zamer-input waterworks bg-white p-2'
                                        />
                                    </Col>
                                    <Col className='mt-2 mt-md-1 ps-md-3 pb-md-2' md={{span: 6, order: 2}}>
                                        <p className='help-text'>Vyplňte ID v případě, že záměru bylo přiděleno.</p>
                                    </Col>
                                </Row>
                            </label>
                        </Row>
                    </>}

                    <Row className='row-wrapper'>
                        <StavbyTable
                            title={'Stavby, pro které se ochranné pásmo stanoví'}

                            urlPath={urlPath}
                            dataToAdd={parcelAndConstructionData.approvedConstructions}
                            approvedConstructions={true}
                            setIsExceeded={setIsExceeded}
                            setDataToAdd={setParcelAndConstructionData}
                        />
                        {isExceeded &&  <p className='d-flex align-items-start p-0 mt-3 stavba-info__text'> <img src={infoCircle} alt="infoCircle" className='me-2' />V jedné žádosti je možné zadat nejvýše 7 staveb.</p>}
                    </Row>

                    <Row className='row-wrapper mb-5'>
                        <p className='p-0 mb-4 title third'>Charakteristika ochranného pásma</p>
                        <label className='d-flex flex-column p-0 mb-4 textarea-input mb-5' style={{ color: protectionZoneCharacteristicsError ? '#C52A3A' : '' }}>
                            Důvody zřízení požadovaného ochranného pásma a odůvodnění jeho rozsahu
                            <Row className='position-relative textarea-with-count-container'>
                                <textarea
                                    className='textarea-with-count mt-2 p-2'
                                    onChange={(e) => { checkLimitAndInsertText(e.target, 0, count, setCount); }}        
                                    rows="5"
                                    style={{ border: protectionZoneCharacteristicsError && 'solid 1px #C52A3A'}} 
                                    defaultValue={request?.form?.protectionZoneCharacteristics ?? ''}
                                    onBlur={(e) => {setRequest(state => ({ ...state, form: { ...state.form, protectionZoneCharacteristics: e.target.value } }));
                                        handleFocused('protectionZoneCharacteristics');
                                    }}
                                />
                                <CharCount 
                                    value={request?.form?.protectionZoneCharacteristics} setCount={setCount} propToUpdate={0} count={count[0].count} limit={count[0].limit}
                                />
                            </Row>
                            {/* {(count[0].count >= count[0].limit) && <Row><p className='d-flex align-items-center p-0 kontrola-info--warning ps-3 ps-md-0 my-1'><img src={warningUnfilled} alt="infoCircle" className='me-2' />Dosažen limit počtu znaků.</p></Row>}
                            {(count[0].limit === 10000 && count[0].count >= 1000) && <Row className='mb-2'><p className='d-flex align-items-center p-0 kontrola-info ps-3 ps-md-0 my-1'><img src={infoCircle} alt="infoCircle" className='me-2' />Text bude automaticky přiložen k žádosti jako příloha, neboť překročil 1000 znaků.</p></Row>} */}
                        </label>
                        <label className='d-flex flex-column p-0 textarea-input mb-5 mt-3' style={{ color: proposalProhibitionsRestrictionsError ? '#C52A3A' : '' }}>
                            Návrh zákazů a omezení povolování a provádění staveb nebo činností, jejich důsledky na funkční a prostorové uspořádání území a návrhy z toho vyplývajících technických a organizačních opatření
                            <Row className='position-relative textarea-with-count-container'>
                                <textarea
                                    className='textarea-with-count mt-2 p-2'
                                    onChange={(e) => { checkLimitAndInsertText(e.target, 1, count, setCount); }}        
                                    rows="5"
                                    style={{ border: proposalProhibitionsRestrictionsError && 'solid 1px #C52A3A'}} 
                                    defaultValue={request?.form?.proposalProhibitionsRestrictions ?? ''}
                                    onBlur={(e) => {setRequest(state => ({ ...state, form: { ...state.form, proposalProhibitionsRestrictions: e.target.value } }));
                                        handleFocused('proposalProhibitionsRestrictions');
                                    }}
                                />
                                <CharCount 
                                    value={request?.form?.proposalProhibitionsRestrictions} setCount={setCount} propToUpdate={1} count={count[1].count} limit={count[1].limit}
                                />
                            </Row>
                            {/* {(count[1].count >= count[1].limit) && <Row><p className='d-flex align-items-center p-0 kontrola-info--warning ps-3 ps-md-0 my-1'><img src={warningUnfilled} alt="infoCircle" className='me-2' />Dosažen limit počtu znaků.</p></Row>}
                            {(count[1].limit === 10000 && count[1].count >= 1000) && <Row className='mb-2'><p className='d-flex align-items-center p-0 kontrola-info ps-3 ps-md-0 my-1'><img src={infoCircle} alt="infoCircle" className='me-2' />Text bude automaticky přiložen k žádosti jako příloha, neboť překročil 1000 znaků.</p></Row>} */}
                        </label>
                    </Row>

                    <Row className='row-wrapper'>
                        <p className='p-0 mb-4 title third'>Předpokládaná doba trvání ochranného pásma</p>
                        <form className='p-0'>
                            <label className='ls-1p25 p-0 pb-2 d-flex align-items-start'>
                                <input
                                    className='me-3'
                                    type="radio"
                                    name='zadost'
                                    checked={request?.form?.estimatedDurationEntire ?? false}
                                    onChange={()=>{
                                        setRequest(state => ({...state, form: {...state.form, estimatedDurationEntire: true, estimatedDurationDate: '' }}));                              
                                    }}
                                />
                            po celou dobu trvání stavby
                            </label>
                            <label className='ls-1p25 p-0 mb-4 d-flex align-items-start'>
                                <input
                                    className='me-3'
                                    type="radio"
                                    name='zadost'
                                    checked={!request?.form?.estimatedDurationEntire}
                                    onChange={()=>{
                                        setRequest(state => ({...state, form: {...state.form, estimatedDurationEntire: false }}));
                                    }}
                                />
                            doba trvání do
                            </label>
                            {!request?.form?.estimatedDurationEntire
                                &&
                            <>
                                <Col md={6}>
                                    <label className='d-flex flex-column povoleni-label'style={{ color: (estimatedDurationDateError || isDateError.protectiveZoneDate) ? '#C52A3A' : '' }}>
                                        Datum do ukončení ochranného pásma
                                        <input 
                                            type='date'
                                            value={request?.form?.estimatedDurationDate ?? ''}
                                            style={{ border: (estimatedDurationDateError || isDateError.protectiveZoneDate) && 'solid 1px #C52A3A'}} 
                                            onChange={(e) => {
                                                setRequest(state => ({ ...state, form: { ...state.form, estimatedDurationDate: e.target.value } })); 
                                            }}
                                            onBlur={(e) => {
                                                const validatedDate = dateValidation(e.target.value); 
                                                validatedDate && setRequest(state => ({ ...state, form: { ...state.form, estimatedDurationDate: '' } }));
                                                setIsDateError(state => ({...state, protectiveZoneDate: validatedDate}));
                                                handleFocused('estimatedDurationDate');
                                            }}
                                            className='povoleni-input bg-white p-2 mt-1'
                                        />
                                    </label>
                                </Col>
                                {estimatedDurationDateError && !isDateError.protectiveZoneDate && <Col xs={{order: 3}} md={{ span: 6, order: 3}} className='mt-2'>
                                    <p className='d-flex align-items-center p-0 warning-text'><img src={errorIcon} alt='warning' className='me-2' style={{ height: '1.1rem', width: '1.1rem' }} />Bez vyplnění pole “Datum do ukončení ochranného pásma” není možné pokračovat.</p>
                                </Col>}

                                {isDateError.protectiveZoneDate && <Container className='mt-2' style={{color: '#C52A3A', fontSize: '0.751rem'}}>
                                    <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                            Nesprávný formát vyplnění, zkuste to znovu.
                                </Container>}
                            </>
                            }
                        </form>
                    </Row>

                    <PozemkyTable
                        title={'Navrhovaný rozsah ochranného pásma'}
                        
                        urlPath={urlPath}
                        dataToAdd={parcelAndConstructionData.buildParcels}
                        setDataToAdd={setParcelAndConstructionData}
                    />

                </Container>}
        </>
    );
};

ZamerComponent10.propTypes = {

    urlPath: PropTypes.string.isRequired,
};

export default ZamerComponent10;
