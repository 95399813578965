import axios from 'axios';
import urlJoin from 'url-join';
import apiConfig from '../../../config/api-config.js';

export const downloadDocumentVersion = async (bppPid, token) => {
    try {
        const source = axios.CancelToken.source();
        const response = await axios.get(
            urlJoin(apiConfig.getAndQueryBaseUrl, `projects/download-bpp/${bppPid}?fileName=dokumentace.zip`),
            {
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                cancelToken: source.token,
            }
        );
        return response;
    } catch (error) {
        if (axios.isCancel(error)) {
            console.log('Request canceled', error.message);
        } else {
            console.log('Error', error);
        }
    }
};
