import { jwtDecode } from 'jwt-decode';
import { TOKEN_PARAM_NAME, AUTH_PARAM_NAME, ERROR_CODE_PARAM_NAME } from '../constants.js';
import { matchPath, removeOAuthLocalStorageWithReload } from '../utils.js';

const useTokenType = (token) => {
    const isOfficerRoute = matchPath('/urednik', window.location.pathname);
    const searchParams = new URLSearchParams(window.location.search);
    const newToken = searchParams.get(TOKEN_PARAM_NAME);
    const auth = searchParams.get(AUTH_PARAM_NAME) || '';
    const errorCode = searchParams.get(ERROR_CODE_PARAM_NAME) || '';

    if (!token) {
        return null;
    }
    
    if (!!isOfficerRoute && auth === 'false' && errorCode === '401' && !!token) {
        return removeOAuthLocalStorageWithReload();
    }

    if (!!isOfficerRoute && !!newToken && !!token) {
        const areTokensSame = newToken === token;

        if (!areTokensSame) {
            return removeOAuthLocalStorageWithReload();
        }
    }

    return jwtDecode(token)?.tokenType;
};

export default useTokenType;