import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'react-oauth2-code-pkce';
import { useParams } from 'react-router-dom';
import { insertFilePidandFetchDokumentaceDetail } from '../../apiCalls/componentsApiCalls';
import { Container } from 'react-bootstrap';
import DokumentaceDetailHeaderSection from './DokumentaceDetailHeaderSection';
import DokumentaceDetailMainSection from './DokumentaceDetailMainSection';
import ErrorPage from '../ErrorPage';

const DokumentaceApiCall = () => {
    const [data, setData] = useState({
        versions: [],
        owner: false,
        zamerDetail: null,
        error: false,
    });
    // TODO temp state
    const [fetched, setFetched] = useState(false);
    const { token: documentationToken, filePid } = useParams();
    const { token } = useContext(AuthContext);
    const fetchDetail = async (signal) => {
        try {
            const response = await insertFilePidandFetchDokumentaceDetail(signal, token, documentationToken, filePid);
            
            if (response instanceof Object) {
                const {zamerDetail, owner, versions} = response;
                setData(prev => ({...prev, zamerDetail, owner, versions}));
                console.log('OWNER', owner);
            }

            if(!response) {
                setData(prev => ({...prev, error: true}));
            }
        } catch {
            console.log('fetch error dokumentace');
        } finally {
            setFetched(true);
        }
    };

    console.log('Api call response 2', data);

    useEffect(() => {
        if (data?.versions?.length > 0) {
            return;
        }

        const controller = new AbortController();
        const signal = controller?.signal;
        // TODO temp state
        if (!fetched) {
            fetchDetail(signal);
        }
            
        return () => {
            controller?.abort();
        };
    }, []);

    useEffect(() => {
        console.log('OWNER', data?.owner);
    }
    , [data.owner]);

    return (
        <div>
            {!fetched && (
                <div className='px-2 py-4 px-md-5 px-xl-0'>
                    <span>Načítám detail dokumentace...</span>
                </div>
            )}
            {data?.zamerDetail && fetched && (
                <Container fluid className='px-2 py-4 px-md-5 px-xl-0 d-flex flex-column base-width'>
                    {data?.zamerDetail && <DokumentaceDetailHeaderSection item={data?.zamerDetail} owner={data?.owner} />}
                    {data?.versions?.length > 0 && <DokumentaceDetailMainSection item={ { versions: [...(data?.versions || [])], zamerDetail: {...(data?.zamerDetail || {})} } }/>}
                </Container>
            )}
            {data?.error && fetched && <ErrorPage errorName='Dokumentace nenalezena' errorCode={404} navigateTo='/dokumentace'/>}
        </div>
    );
};

export default DokumentaceApiCall;
