export const getItems = () => {
    return [
        {
            stage: 1,
            name: 'Záměr',
            error: false,
            warning: false,
            seen: false,    
        },
        {
            stage: 2,
            name: 'Žadatel',
            error: false,
            warning: false,
            seen: false,    
        },
        {
            stage: 3,
            name: 'Dokumentace',
            error: false,
            warning: false,
            seen: false,    
        },
    ];
};