import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import '../../css/tableView.css';
import { arrowDownDark, arrowUpBlue } from '../../assets';
import {Link, useNavigate} from 'react-router-dom';
import { Container } from 'react-bootstrap';
import useFirstRender from '../CustomHooks/useFirstRender';
import {makeCopyRequest} from '../../helperFunctions/helpers.js';
import {PATHS} from '../../constants/sharedConstants.js';
import {AuthContext} from 'react-oauth2-code-pkce';

const ZadostiTableDisplay = ({ items, postBody, setPostBody, isReset }) => {
    const { token } = useContext(AuthContext);
    const navigate = useNavigate();
    const [sortKeys, setSortKeys] = useState({
        pid: [...(postBody.sorts || [])].find(el => el.field === 'pid')?.asc ?? null, // ?bool
        requestType: [...(postBody.sorts || [])].find(el => el.field === 'requestType')?.asc ?? null, // ?bool
        projectId: [...(postBody.sorts || [])].find(el => el.field === 'projectId')?.asc ?? null, // ?bool
        projectName: [...(postBody.sorts || [])].find(el => el.field === 'projectName')?.asc ?? null, // ?bool
        status: [...(postBody.sorts || [])].find(el => el.field === 'status')?.asc ?? null, // ?bool
        department: [...(postBody.sorts || [])].find(el => el.field === 'department')?.asc ?? null, // ?bool
        requestDate: [...(postBody.sorts || [])].find(el => el.field === 'requestDate')?.asc ?? null, // ?bool
    });
    const firstRender = useFirstRender();

    const handleClick = (field, setSortKeys) => {
        setSortKeys(prev => {
            const ref = {};
            for (const key in prev) {
                if (key === field) {
                    ref[key] = !(prev[key]);
                } else {
                    ref[key] = null;
                }
            }

            return ref;
        });
    };

    useEffect(() => {
        if (firstRender) {
            return;
        }

        if (isReset) {
            setSortKeys({
                pid: null,
                requestType:  null,
                projectId:  null,
                projectName:  null,
                status:  null,
                department:  null,
                requestDate:  null,
            });
        }
    }, [isReset]);
    
    useEffect(() => {
        if (Object.values(sortKeys).every(val => val === null)) {
            return;
        }

        const sortKey = Object.keys(sortKeys).find(key => sortKeys[key] !== null);
        setPostBody(prev => {
            const addedSort = {field: sortKey, asc: sortKeys[sortKey]};
            return {...prev, sorts: [...prev.sorts.filter(el => Object.keys(sortKeys).indexOf(el.field ) === -1), {...addedSort}]};
        });
    }, [sortKeys]);

    const handleClickCopy = async (id) => {

        try {
            const { path, applicationId } = await makeCopyRequest(
                id,
                token,
                PATHS
            );

            if (!path || !applicationId) {
                return null;
            }

            return navigate(`/zadost/${path.target}/${applicationId}`, { state: { isCopied: true } });
        } catch (e) {
            // TODO error handling
            console.log(e);
        }
    };


    return (
        <Container style={{padding: '0', margin: '0'}}>
            <table className="border" style={{minWidth: '1100px' , width: '100%'}}>
                <thead>
                    <tr>
                        {/* TODO add asc and desc icons, now there's only one icon */}
                        <th className='py-2 px-3' onClick={() => handleClick('pid', setSortKeys)}><p className='table-head py-2 d-flex'>PID<img className='ms-2' src={sortKeys.pid ? arrowUpBlue : arrowDownDark} alt='arrowDownDark' /></p></th>
                        <th className='py-2 px-3' onClick={() => handleClick('requestType', setSortKeys)}><p className='table-head py-2 d-flex'>Typ žádosti<img className='ms-2' src={sortKeys.requestType ? arrowUpBlue : arrowDownDark} alt='arrowDownDark' /></p></th>
                        <th className='py-2 px-3' onClick={() => handleClick('projectId', setSortKeys)}><p className='table-head py-2 d-flex'>Číslo záměru<img className='ms-2' src={sortKeys.projectId ? arrowUpBlue : arrowDownDark} alt='arrowDownDark' /></p></th>
                        <th className='py-2 px-3' onClick={() => handleClick('projectName', setSortKeys)}><p className='table-head py-2 d-flex'>Název záměru<img className='ms-2' src={sortKeys.projectName ? arrowUpBlue : arrowDownDark} alt='arrowDownDark' /></p></th>
                        <th className='py-2 px-3' onClick={() => handleClick('status', setSortKeys)}><p className='table-head py-2 d-flex'>Stav<img className='ms-2' src={sortKeys.status ? arrowUpBlue : arrowDownDark} alt='arrowDownDark' /></p></th>
                        {items.some(item => item.department) && <th className='py-2 px-3' onClick={() => handleClick('department', setSortKeys)}><p className='table-head py-2 d-flex'>Podáno na<img className='ms-2' src={sortKeys.department ? arrowUpBlue : arrowDownDark} alt='arrowDownDark' /></p></th>}
                        <th className='py-2 px-3' onClick={() => handleClick('requestDate', setSortKeys)}><p className='table-head py-2 d-flex'>Podáno<img className='ms-2' src={sortKeys.requestDate ? arrowUpBlue : arrowDownDark} alt='arrowDownDark' /></p></th>
                    </tr>
                </thead>
                <tbody>
                    {items.length > 0 && items.map((item, idx) => (<tr key={item.id ?? idx}>
                        <td className='py-2 px-3'><p className='row-number py-2 text-truncate' title={item.pid}>{item.pid}</p></td>
                        <td className='py-2 px-3'><p className='row-info py-2 text-truncate' title={item.requestType}>{item.requestType}</p></td>
                        <td className='py-2 px-3'><p className='row-info py-2 text-truncate' title={item.projectId}>{item.projectId}</p></td>
                        <td className='py-2 px-3'><p className='row-info py-2 text-truncate' title={item.projectName}>{item.projectName}</p></td>
                        <td className='py-2 px-3'><p className='row-info py-2 text-truncate' title={item?.status === ('podano' || 'Podáno') ? 'Podáno' : item?.status === ('vyrizen' || 'Vyřízen') ? 'Podáno' : item?.status}>
                            {item?.status === ('podano' || 'Podáno') ? 'Podáno' : item?.status === ('vyrizen' || 'Vyřízen') ? 'Podáno' : item?.status}
                        </p></td>
                        {items.some(item => item.department) && <td className='py-2 px-3'><p className='row-info py-2 text-truncate' title={item.department}>{item.department ?? ''}</p></td>}
                        <td className='py-2 px-3'><p className='row-info py-2 text-truncate' title={item.requestDate}>{item.requestDate}</p></td>
                        <td className='py-2 px-2'>
                            <Link onClick={() => handleClickCopy(item.pid)}>
                                <Container className='d-flex justify-content-center align-items-center detail-button'>
                                    Vytvořit kopii
                                </Container>
                            </Link>
                        </td>
                        <td className='py-2 px-2'>
                            <Link to={`/zadosti/${encodeURIComponent(item.pid)}`}>
                                <Container className='d-flex justify-content-center align-items-center detail-button'>
                                    Zobrazit detail
                                </Container>
                            </Link>
                        </td>
                    </tr>))}
                </tbody>
            </table>
        </Container>);
};

ZadostiTableDisplay.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        requestType: PropTypes.string, pid: PropTypes.string, projectId: PropTypes.string, projectName: PropTypes.string, status: PropTypes.string, department: PropTypes.string, requestDate: PropTypes.string,
    })),
    setPostBody: PropTypes.func,
    isReset: PropTypes.bool,
    postBody: PropTypes.object,
};

export default ZadostiTableDisplay;
