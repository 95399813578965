import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import DokumentaceVersionCard from './DokumentaceVersionCard';
import DokumentaceVersionsTable from './DokumentaceVersionsTable';
import ViewSwticher from '../ViewSwticher';

const DokumentaceVersionsOlder = ({ items }) => {
    const [isActive, setIsActive] = useState('block');

    return (
        <div className='d-flex flex-column'>
            <div>
                <ViewSwticher
                    isActive={isActive}
                    setIsActive={setIsActive}
                />
            </div>
            <h5 className='py-4 fw-500'>Starší verze</h5>
            {(Array.isArray(items) && items?.length > 0) && (
                <Row className='row-revert-x-margin'>
                    {isActive === 'block' && items.map((el, idx) => (
                        <Col key={el.bppPid || idx} xs={12} md={6} lg={4} className='row-revert-x-padding'>
                            <DokumentaceVersionCard item={el} />
                        </Col>
                    ))}
                    {isActive === 'table' && <>
                        <Col>
                            <DokumentaceVersionsTable items={items} />
                        </Col>
                    </>}
                </Row>)}
        </div>
    );
};

DokumentaceVersionsOlder.propTypes = {
    items: PropTypes.any,
};

export default DokumentaceVersionsOlder;
