import React, { useContext, useEffect } from 'react';
import { ZamerSelectionControls } from '../index.js';
import { shouldNotSave } from '../../helperFunctions/helpers.js';
import { formSave } from '../../apiCalls/formApiCalls.js';
import axios from 'axios';
import { AuthContext } from 'react-oauth2-code-pkce';
import PropTypes from 'prop-types';
import usePrevious from '../CustomHooks/usePrevious.jsx';
import useFirstRender from '../CustomHooks/useFirstRender';

const ZamerControlsMinimal = ({
    urlPath,
    isDocumentationWithId = false,
    request,
    setRequest,
    buildIntentionModel,
    state,
    setState,
    title = ''
}) => {
    const { token } = useContext(AuthContext);
    const firstRender = useFirstRender();
    const prevRequest = usePrevious(request);
    const buildIntention = request?.buildIntention;

    const saveChanges = async () => {
        const source = axios.CancelToken.source();
        // add accompanyingStatus to request to follow up on the status but do not send it
        // eslint-disable-next-line no-unused-vars
        const {accompanyingDocument, ...rest} = request || {};
        const filteredRequest = {...rest};
        await formSave(filteredRequest, token, urlPath, source);
    };

    useEffect(() => {
        if (shouldNotSave(prevRequest?.buildIntention, buildIntention, firstRender)) {
            return;
        }

        if (buildIntention?.title?.value === null) {
            return;
        }

        saveChanges();
    }, [buildIntention]);
    
    return (
        <>
            <ZamerSelectionControls
                title={title}
                request={request}
                setRequest={setRequest}
                state={state}
                setState={setState}
                buildIntentionModel={buildIntentionModel}
                isDocumentationWithId={isDocumentationWithId}
            />
        </>
    );
};

ZamerControlsMinimal.propTypes = {

    urlPath: PropTypes.string,
    isDocumentationWithId: PropTypes.any,
    request: PropTypes.object,
    setRequest: PropTypes.func,
    buildIntentionModel: PropTypes.object,
    state: PropTypes.any,
    setState: PropTypes.any,
    title: PropTypes.string
};

export default ZamerControlsMinimal;