import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { AuthContext } from 'react-oauth2-code-pkce';
import { useNavigate } from 'react-router-dom';
import { Link, useLocation } from 'react-router-dom';
import moment from 'moment';
import cs from 'moment/locale/cs';
import { PATHS } from '../../constants/sharedConstants';
import { makeCopyRequest } from '../../helperFunctions/helpers';
import { iconMakeCopy } from '../../assets';

import '../../css/zadosti.css';

moment.locale('cs', cs);

const ZadostiBlockDisplay = ({ item }) => {
    const { token } = useContext(AuthContext);
    const location = useLocation();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [requestDetails, setRequestDetails] = useState({
        requestUrl: '',
        requestDate: '',
    });

    const handleDateAndTitle = (date) => {
        setRequestDetails((prev) => ({
            ...prev,
            // requestDate: moment(date, 'YYYY-MM-DD hh:mm:ss').fromNow()
            requestDate: moment(date).format('LL'),
        }));
    };

    useEffect(() => {
        if (!item.pid) {
            return;
        }
        handleDateAndTitle(item.requestDate);
    }, []);

    const handleClick = async (id) => {
        setIsLoading(true);
        try {
            const { path, applicationId } = await makeCopyRequest(
                id,
                token,
                PATHS
            );

            if (!path || !applicationId) {
                return null;
            }

            return navigate(`/zadost/${path.target}/${applicationId}`, { state: { isCopied: true } });
        } catch (e) {
            // TODO error handling
            setIsLoading(false);
            console.log(e);
        }
    };

    return (
        <Container
            className='d-flex flex-column border rounded px-3 py-4 zadosti-container'
            fluid
        >
            <Row className='d-flex mb-3'>
                <p className='p-0 zadosti-title'>{item?.requestType}</p>
            </Row>

            <Row className='mb-4'>
                <Col>
                    <Row className='d-flex align-items-center mb-3'>
                        <p className='p-0 zadosti-id'>PID žádosti: {item?.pid}</p>
                    </Row>
                    {item?.projectId && (
                        <Row className='d-flex align-items-center py-1 border-bottom'>
                            <Col sm={4}>
                                <p className='zadosti-row-name'>Číslo záměru</p>
                            </Col>
                            <Col sm={8}>
                                <p className='zadosti-row-info'>
                                    {item?.projectId}
                                </p>
                            </Col>
                        </Row>
                    )}
                    <Row className='d-flex align-items-center py-1 border-bottom'>
                        <Col sm={4}>
                            <p className='zadosti-row-name'>Záměr</p>
                        </Col>
                        <Col sm={8}>
                            <p className='zadosti-row-info'>
                                {item?.projectName}
                            </p>
                        </Col>
                    </Row>
                    <Row className='d-flex align-items-center py-1 border-bottom'>
                        <Col sm={4}>
                            <p className='zadosti-row-name'>Stav</p>
                        </Col>
                        <Col sm={8}>
                            <p className='zadosti-row-info'>
                                {item?.status === ('podano' || 'Podáno')
                                    ? 'Podáno'
                                    : item?.status === ('vyrizen' || 'Vyřízen')
                                        ? 'Podáno'
                                        : item?.status}
                            </p>
                        </Col>
                    </Row>
                    {item?.department && (
                        <Row className='d-flex align-items-center py-1 border-bottom'>
                            <Col sm={4}>
                                <p className='zadosti-row-name'>Podáno na</p>
                            </Col>
                            <Col sm={8}>
                                <p className='zadosti-row-info'>
                                    {item?.department}
                                </p>
                            </Col>
                        </Row>
                    )}
                    <Row className='d-flex align-items-center py-1'>
                        <Col sm={4}>
                            <p className='zadosti-row-name'>Podáno</p>
                        </Col>
                        <Col sm={8}>
                            {/* <p className='zadosti-row-info'>{requestDetails.requestDate === 'Invalid date' ? 'Neuvedeno' : requestDetails.requestDate }</p> */}
                            <p className='zadosti-row-info'>
                                {requestDetails.requestDate}
                            </p>
                        </Col>
                    </Row>
                    {/* add temporary guard as BE is not ready */}
                    {item?.bppPid && (
                        <Row className='d-flex align-items-center py-1 border-bottom'>
                            <Col sm={4}>
                                <p className='zadosti-row-name'>ID dokumentace</p>
                            </Col>
                            <Col sm={8}>
                                <p className='zadosti-row-info'>
                                    {item?.bppPid}
                                </p>
                            </Col>
                        </Row>
                    )}
                </Col>
            </Row>

            <Row className='mt-auto justify-self-end'>
                <Col>
                    <Row className='mb-2'>
                        <Col>
                            <button
                                type='button'
                                className='button-more-info d-flex align-items-center justify-content-center mw-100'
                                onClick={() => handleClick(item?.pid)}
                                disabled={isLoading}
                                aria-disabled={isLoading}
                            >
                                <img src={iconMakeCopy} alt='Vytvořit kopii' />
                                <p className='button-create-req-text'>
                                    {isLoading
                                        ? 'Vytvářím kopii...'
                                        : 'Vytvořit kopii'}
                                </p>
                            </button>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Link
                                to={`/zadosti/${encodeURIComponent(item?.pid)}`}
                                state={{ from: location }}
                                className='button-fill-in-container'
                            >
                                <button className='button-fill-in'>
                                    Zobrazit detail
                                </button>
                            </Link>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

ZadostiBlockDisplay.propTypes = {
    item: PropTypes.shape({
        requestType: PropTypes.string,
        pid: PropTypes.string,
        projectId: PropTypes.string,
        projectName: PropTypes.string,
        status: PropTypes.string,
        department: PropTypes.string,
        created: PropTypes.string,
        requestDate: PropTypes.string,
    }),
};

export default ZadostiBlockDisplay;
