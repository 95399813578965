import { createSearchParams } from 'react-router-dom';
import { ID_TOKEN_LOCALSTORAGE_NAME, LOGIN_IN_PROGRESS_LOCALSTORAGE_NAME, PKCE_CODE_VERIFIER, PRE_LOGIN_PATH_LOCALSTORAGE_NAME, REFRESH_TOKEN_EXPIRE_LOCALSTORAGE_NAME, STORAGE_PREFIX_KEY, TOKEN_EXPIRE_LOCALSTORAGE_NAME, TOKEN_LOCALSTORAGE_NAME } from './constants.js';

export function redirectToErrorPage(errorCode, navigate) {
    const searchParams = createSearchParams({
        errorCode,
    });
    navigate(`/login-error?${searchParams}`, { replace: true });
}

export function removeOAuthPCKECodeSesstionStorageKey() {
    sessionStorage.removeItem(PKCE_CODE_VERIFIER);
}

export function setOAuthLocalStorageKey(name, value) {
    localStorage.setItem(`${STORAGE_PREFIX_KEY}${name}`, JSON.stringify(value));
}

export function setOAuthLocalStorage(decodedToken, token) {
    // We do not have idToken for Officer
    // setOAuthLocalStorageKey(ID_TOKEN_LOCALSTORAGE_NAME, token);
    setOAuthLocalStorageKey(LOGIN_IN_PROGRESS_LOCALSTORAGE_NAME, false);
    setOAuthLocalStorageKey(REFRESH_TOKEN_EXPIRE_LOCALSTORAGE_NAME, decodedToken.exp);
    setOAuthLocalStorageKey(TOKEN_LOCALSTORAGE_NAME, token);
    setOAuthLocalStorageKey(TOKEN_EXPIRE_LOCALSTORAGE_NAME, decodedToken.exp);

    localStorage.setItem(PRE_LOGIN_PATH_LOCALSTORAGE_NAME, '/');
}

export function removeOAuthLocalStorageKey(name) {
    localStorage.removeItem(`${STORAGE_PREFIX_KEY}${name}`);
}

export function removeOAuthLocalStorage() {
    removeOAuthLocalStorageKey(ID_TOKEN_LOCALSTORAGE_NAME);
    removeOAuthLocalStorageKey(LOGIN_IN_PROGRESS_LOCALSTORAGE_NAME);
    removeOAuthLocalStorageKey(REFRESH_TOKEN_EXPIRE_LOCALSTORAGE_NAME);
    removeOAuthLocalStorageKey(TOKEN_LOCALSTORAGE_NAME);
    removeOAuthLocalStorageKey(TOKEN_EXPIRE_LOCALSTORAGE_NAME);
}

export function removeOAuthLocalStorageWithReload() {
    removeOAuthLocalStorage();

    return window.location.reload();
}

export function matchPath(pattern, pathname) {
    const keys = [];
    const regex = new RegExp(
        '^' +
        pattern
            .replace(/\/:([a-zA-Z]+)/g, (_, key) => {
                keys.push(key);
                return '/([^/]+)';
            })
            .replace(/\*/g, '.*') +
        '$'
    );

    const match = pathname.match(regex);

    if (!match) return null;

    const params = keys.reduce((acc, key, index) => {
        acc[key] = match[index + 1];
        return acc;
    }, {});

    return { pattern, params };
}