import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { cancelAddPerson, handleSaveApplicant, resetApplicant } from '../helperFunctions/modalHelpers';
import { dateValidation } from '../helperFunctions/validationHelpers.js';
import UploadedAttachments from './FileUploads/UploadedAttachments';
import {PLNA_MOC_FIFTH, PLNA_MOC_SECOND} from '../constants/sharedConstants';
import FileUpload from './FileUploads/FileUpload';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { APPLICANT_TYPE_FO, APPLICANT_TYPE_FOP, APPLICANT_TYPE_PO, COUNTRIES, POWER_OF_ATTORNEY_FILE_MODEL } from '../constants/sharedConstants';
import { arrowDownBlue, errorIcon, warningYellow } from '../assets';
import { Link, useLocation } from 'react-router-dom';
import { FindAndProcessIco } from './index';
import { canAddThirdPartyApplicant } from '../helperFunctions/helpers.js';

const initialFocusState = {
    icoNumber: false,
    pscNumber: false,
    shipZip: false,
    phone: false,
    email: false,
};

const ApplicantMoreModal = ({
    show,
    setShow,
    currentApplicant,
    setCurrentApplicant,
    applicantArr,
    setApplicantArr,
    plnaMocForZadatelAttachment,
    plnaMocForZadatelRepreAttachment,
    setZadatelAttachments,
    handleFileInput,
    applicant = 'Přidat žadatele (stavebníka)',
    applicantType = 'žadatel',
    zadatelAttachments = [],
    isDocumentation = false,
}) => {
    const location = useLocation();
    const isDocumentationPage = location.pathname.includes('dokumentace');
    const canProceed = canAddThirdPartyApplicant(currentApplicant, zadatelAttachments, isDocumentation);
    const capitalizedApplicant = applicantType.charAt(0).toUpperCase() + applicantType.slice(1);
    const titleRef = useRef(null);
    const [ focused, setFocused ] = React.useState(initialFocusState);
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    const telPattern = /^[0-9]{9,}$/;
    const zipPattern = /^[0-9]{5}$/;
    const [err, setErr] = useState({
        plnaMocForZadatelAttachmentError: false,
        plnaMocForZadatelRepreAttachmentError: false,
    });
    const [isDateError, setIsDateError] = useState({
        FODate: false,
        attorneyDate: false,
        mandateFromDate: false,
        mandateTODate: false,
        FOBusinessDate: false,
        businessMandateFromDate: false,
        businessMandateTODate: false,
    });
    const canRequestPlnaMocFifth = (((currentApplicant.isFOBusiness && currentApplicant.includeRepresentative)) || (currentApplicant.isPO));
    const isAttorneyForCurrentUserAttached = (plnaMocForZadatelAttachment || []).find(item => item?.personId === currentApplicant?.id);
    const isAttorneyRepreForCurrentUserAttached = (plnaMocForZadatelRepreAttachment || []).find(item => item?.personId === currentApplicant?.id);

    useEffect(() => {
        if (show) { 
            if (currentApplicant.id === null) {
                setCurrentApplicant(prev => ({...prev, id: uuidv4()}));}
        }
    }, [show]);

    useEffect(() => {
        if (plnaMocForZadatelAttachment.length === 0) {
            return;
        }

        setErr(prev => ({...prev, plnaMocForZadatelAttachmentError: false}));

        const taggedAttachments = [...plnaMocForZadatelAttachment].filter(att => att.personId === currentApplicant.id);
        const attachmentToSelect = taggedAttachments[taggedAttachments.length - 1];

        if (!attachmentToSelect) {
            setCurrentApplicant(prev => ({...prev, powerOfAttorneyFile:  POWER_OF_ATTORNEY_FILE_MODEL})); 
            return;
        }
        
        // eslint-disable-next-line no-unused-vars
        const {blobFile, message, abortController, existing, flagged, ...rest} = attachmentToSelect;
        setCurrentApplicant(prev => ({ ...prev, powerOfAttorneyFile: {...rest} }));
    }, [plnaMocForZadatelAttachment]);
    // TODO get rid of extra useEffect
    useEffect(() => {
        if (plnaMocForZadatelRepreAttachment.length === 0) {
            return;
        }

        setErr(prev => ({...prev, plnaMocForZadatelRepreAttachmentError: false}));

        const taggedAttachments = [...plnaMocForZadatelRepreAttachment].filter(att => att.personId === currentApplicant.id);
        const attachmentToSelect = taggedAttachments[taggedAttachments.length - 1];

        if (!attachmentToSelect) {
            setCurrentApplicant(prev => ({...prev, powerOfAttorneyRepresentative:  POWER_OF_ATTORNEY_FILE_MODEL}));
            return;
        }

        // eslint-disable-next-line no-unused-vars
        const {blobFile, message, abortController, existing, flagged, ...rest} = attachmentToSelect;
        setCurrentApplicant(prev => ({ ...prev, powerOfAttorneyRepresentative: {...rest} }));
    }, [plnaMocForZadatelRepreAttachment]);

    const handleFocused = (elementToFocus) => {
        setFocused(prev => ({...prev, [elementToFocus]: true}));
    };

    return (
        <Modal 
            show={show} 
            onHide={() => {
                cancelAddPerson(
                    currentApplicant,
                    setZadatelAttachments,
                    setShow,
                    setCurrentApplicant,
                    applicantArr,
                );
                setFocused(initialFocusState);
            }} 
            size='lg' 
            centered
        >
            <Modal.Header closeButton bsPrefix='d-flex justify-content-between align-items-center px-sm-5 modal-header-custom'>
                <Modal.Title bsPrefix='modal-title' ref={titleRef}>{applicant}</Modal.Title>
            </Modal.Header>
            <Modal.Body bsPrefix='px-sm-5 modal-body-custom'>
                <h3 className='p-0 id-title'>Typ {applicantType}e</h3>
                {/* IDENTIFIKAČNÍ ÚDAJE ŽADATELE */}
                <Row className='row-wrapper'>
                    <form className='d-flex flex-column p-0 form'>
                        <label className='d-flex align-items-center mb-2 label'>
                            <input 
                                type='radio'
                                name='id'
                                value={APPLICANT_TYPE_FO} 
                                className='radio'
                                checked={currentApplicant.isFO}
                                onChange={(e) => { 
                                    resetApplicant(e.target.value, currentApplicant, setCurrentApplicant, setZadatelAttachments);
                                    setFocused(initialFocusState);
                                }} />
                        fyzická osoba
                        </label>
                        <label className='d-flex align-items-center mb-2 label'>
                            <input
                                type='radio' name='id' 
                                value={APPLICANT_TYPE_FOP} 
                                className='radio'
                                checked={currentApplicant.isFOBusiness}
                                onChange={(e) => { 
                                    resetApplicant(e.target.value, currentApplicant, setCurrentApplicant, setZadatelAttachments); 
                                    setFocused(initialFocusState);
                                }} />
                        fyzická osoba podnikající (pokud záměr souvisí s podnikatelskou činností)
                        </label>
                        <label className='d-flex align-items-center label'>
                            <input
                                type='radio' 
                                name='id' 
                                value={APPLICANT_TYPE_PO} 
                                className='radio' 
                                checked={currentApplicant.isPO}
                                onChange={(e) => { 
                                    resetApplicant(e.target.value, currentApplicant, setCurrentApplicant, setZadatelAttachments); 
                                    setFocused(initialFocusState);
                                }} />
                        právnická osoba
                        </label>
                    </form>
                </Row>

                {/* ZÁKLADNÍ ÚDAJE */}
                <Row className='row-wrapper'>
                    <form className='p-0'>
                        {!currentApplicant.isFO &&
                        <Container className='mb-3'>
                            <FindAndProcessIco
                                thirdPerson={true}
                                setRequest={setCurrentApplicant}
                                request={currentApplicant}
                                handleFocused={handleFocused}
                                focusKey={'icoNumber'}
                                focused={focused}
                            />
                        </Container>
                        }
                        {!currentApplicant.isPO && <>
                            <label className='d-flex flex-column flex-lg-row align-items-start align-items-lg-end mb-4 label'>
                                <Row className='d-flex mb-3 mb-md-0 me-md-4 w-100'>
                            Jméno
                                    <input type='text' className='id-input with-text' value={currentApplicant.names} onChange={(e) => setCurrentApplicant(state => ({ ...state, names: e.target.value }))} />
                                </Row>
                                <p className='mb-md-2 input-help-text'>Uveďte všechna jména</p>
                            </label>
                            <label className='d-flex flex-column mb-4 label'>
                            Příjmení
                                <input type='text' className='id-input' value={currentApplicant.lastName} onChange={(e) => setCurrentApplicant(state => ({ ...state, lastName: e.target.value }))} />
                            </label>
                            <label className='d-flex flex-column flex-lg-row align-items-start align-items-lg-end  mb-4 label'>
                                <Row className='d-flex mb-3 mb-md-0 me-md-4 w-100'>
                            Rodné příjmení
                                    <input type='text' className='id-input with-text' value={currentApplicant.lastNameOrigin} onChange={(e) => setCurrentApplicant(state => ({ ...state, lastNameOrigin: e.target.value }))} />
                                </Row>
                                <p className='mt-md-2 input-help-text align-content-sm-start'>Vyplňte pouze v případě, že rodné příjmení je odlišné od příjmení</p>
                            </label>
                        </>}
                        
                        {(currentApplicant.isFO || currentApplicant.isFOBusiness) && 
                        <Col className='mb-4' md={6}>
                            <label className='d-flex flex-column label' style={{ color: isDateError.FODate ? '#C52A3A' : '' }}>
                        Datum narození
                                <input 
                                    type='date' 
                                    className='id-input' 
                                    style={{ border: isDateError.FODate ? 'solid 1px #C52A3A' : '' }}
                                    value={currentApplicant?.dateOfBirth ?? ''} 
                                    onChange={(e) => setCurrentApplicant(state => ({ ...state, dateOfBirth: e.target.value }))} 
                                    onBlur={(e) => {
                                        const validatedDate = dateValidation(e.target.value); 
                                        validatedDate && setCurrentApplicant(state => ({ ...state, dateOfBirth: '' }));
                                        setIsDateError(state => ({...state, FODate: validatedDate}));
                                    }}
                                />
                            </label>
                            {isDateError.FODate && <Container className='mt-2' style={{color: '#C52A3A', fontSize: '0.751rem'}}>
                                <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                            Nesprávný formát vyplnění, zkuste to znovu.
                            </Container>}
                        </Col>
                        }

                        {!currentApplicant.isPO && <label className='d-flex flex-column mb-4 label'>
                        Státní příslušnost
                            <select type='select' value={currentApplicant.nationality} onChange={(e) => setCurrentApplicant(state => ({ ...state, nationality: e.target.value }))} className='id-input' >
                                <option value={''}>Vyberte</option>
                                {COUNTRIES.map((country) => (
                                    <option key={country.value} value={country.value}>{country.label}</option>
                                ))}
                            </select>
                        </label>}
                        
                        {currentApplicant.isFOBusiness && <label className='d-flex flex-column label'>
                        Dodatek odlišující osobu podnikatele nebo druh podnikání vztahující se k této osobě nebo jí provozovanému druhu podnikání (nepovinné)
                            <input type='text' className='id-input with-text' value={currentApplicant.appendix} onChange={(e) => setCurrentApplicant(state => ({ ...state, appendix: e.target.value }))} />
                        </label>}
                        {currentApplicant.isPO && <label className='d-flex flex-column label'>
                        Název nebo obchodní firma
                            <input type='text' className='id-input' value={currentApplicant.poName} onChange={(e) => setCurrentApplicant(state => ({ ...state, poName: e.target.value }))} />
                        </label>}
                    </form>
                </Row>

                {/* TRVALÝ POBYT / MÍSTO PODNIKÁNÍ */}
                <Row className='border p-4 row-wrapper'>
                    <h3 className='p-0 id-title'>{currentApplicant.isFO ? 'Trvalý pobyt' : currentApplicant.isFOBusiness ? 'Místo podnikání' : 'Sídlo'}</h3>
                    <form className='p-0'>
                        {/* <label className='d-flex flex-column mb-4 label'>
                        Obec
                            <input type='text' className='id-input' value={currentApplicant.address?.city} onChange={(e) => setCurrentApplicant(state => ({ ...state, address: {...state.address, city: e.target.value} }))} />
                        </label> */}
                        <label className='d-lg-flex align-items-end mb-4 label justify-content-between'>
                            <Row className='d-flex flex-column mb-3 mb-lg-0 me-lg-4'>
                            Obec
                                <input type='text' className='id-input with-text' value={currentApplicant.address?.city} onChange={(e) => setCurrentApplicant(state => ({ ...state, address: {...state.address, city: e.target.value} }))} />
                            </Row>
                            {currentApplicant.isFOBusiness && <p className='mt-md-2 input-help-text'>Uveďte adresu zapsanou v obchodním rejstříku nebo jiné zákonem upravené evidenci jako místo podnikání (sídlo).</p>}
                        </label>
                        <label className='d-flex flex-column mb-4 label'>
                        Část obce
                            <input type='text' className='id-input' value={currentApplicant.address?.cityPart} onChange={(e) => setCurrentApplicant(state => ({ ...state, address: {...state.address, cityPart: e.target.value}}))} />
                        </label>
                        <label className='d-flex flex-column mb-4 label'>
                        Ulice
                            <input type='text' className='id-input' value={currentApplicant.address?.street} onChange={(e) => setCurrentApplicant(state => ({ ...state, address: {...state.address, street: e.target.value}}))} />
                        </label>
                        <label className='d-lg-flex align-items-end mb-4 label justify-content-between'>
                            <Row className='d-flex flex-column mb-3 mb-lg-0 me-lg-4'>
                            Číslo popisné
                                <input type='text' className='id-input with-text' value={currentApplicant.address?.descNum} onChange={(e) => setCurrentApplicant(state => ({ ...state, address: {...state.address, descNum: e.target.value} }))} />
                            </Row>
                            <p className='mt-md-2 input-help-text'>Pokud je místo čísla popisného přiděleno číslo evidenční, uveďte před číslem písmeno E.</p>
                        </label>
                        <label className='d-flex flex-column mb-4 label'>
                        Číslo orientační
                            <input type='text' className='id-input' value={currentApplicant.address?.orientNum} onChange={(e) => setCurrentApplicant(state => ({ ...state, address: {...state.address, orientNum: e.target.value}}))} />
                        </label>
                        <label className='d-flex flex-column label'>
                        PSČ
                            <input 
                                // {...(focused.pscNumber && { focused: 'true' })}
                                style={{border: focused.pscNumber && currentApplicant.address?.zip?.toString().trim()?.length > 0  &&
                                    !zipPattern.test(currentApplicant.address.zip?.toString().trim().replace(/\s+/g, ''))
                                    ? '#C78300 solid 1px'
                                    : ''}}
                                type='text' 
                                className='id-input' 
                                value={currentApplicant.address?.zip}
                                onChange={(e) => setCurrentApplicant(state => ({ ...state, address: {...state.address, zip: e.target.value}}))} 
                                onBlur={() => {
                                    handleFocused('pscNumber');
                                }}
                            />
                            {focused.pscNumber && currentApplicant.address?.zip?.toString().trim()?.length > 0  &&
                                    !zipPattern.test(currentApplicant.address.zip?.toString().trim().replace(/\s+/g, '')) &&
                                    <Container style={{color: '#C78300', fontSize: '0.751rem', marginTop: '.5rem'}}>
                                        <img src={warningYellow} alt='warning icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                        Chyba formátu &quot;PSČ&quot; může obsahovat pouze pět číslic.
                                    </Container>}
                        </label>
                    </form>
                </Row>

                {(currentApplicant.isFOBusiness) && (<Row className='row-wrapper'>
                    <form className='p-0'>
                        <label className='id-checkbox-label'>
                            <input type='checkbox' className='id-checkbox-input' checked={currentApplicant.includeRepresentative}
                                onChange={(e) => { setCurrentApplicant(state => ({ ...state,  includeRepresentative: e.target.checked }));}}
                            />
                            Zastoupena jinou osobou
                        </label>
                    </form>
                </Row>)}


                {/* POVĚŘENÁ OSOBA */}
                {(currentApplicant.isPO)  && <Row className='border p-4 row-wrapper'>
                    <h3 className='p-0 id-title'>Osoba pověřená jednat jménem právnické osoby</h3>
                    <form className='p-0'>
                        <label className='d-lg-flex align-items-end mb-4 label'>
                            <Row className='d-flex flex-column mb-3 mb-md-0 me-md-4'>
                                Jméno
                                <input type='text' className='id-input with-text' value={currentApplicant.names} onChange={(e) => setCurrentApplicant(state => ({ ...state, names: e.target.value }))} />
                            </Row>
                            <p className='mt-md-2 input-help-text'>Uveďte všechna jména</p>
                        </label>
                        <label className='d-flex flex-column mb-4 label'>
                            Příjmení
                            <input type='text' className='id-input' value={currentApplicant.lastName} onChange={(e) => setCurrentApplicant(state => ({ ...state, lastName: e.target.value }))} />
                        </label>
                        <label className='d-lg-flex align-items-end mb-4 label'>
                            <Row className='d-flex flex-column mb-3 mb-md-0 me-md-4'>
                                Rodné příjmení
                                <input type='text' className='id-input with-text' value={currentApplicant.lastNameOrigin} onChange={(e) => setCurrentApplicant(state => ({ ...state, lastNameOrigin: e.target.value }))} />
                            </Row>
                            <p className='mt-md-2 input-help-text'>Vyplňte pouze v případě, že rodné příjmení je odlišné od příjmení</p>
                        </label>
                        <Col className='mb-4' md={6}>
                            <label className='d-flex flex-column label' style={{ color: isDateError.attorneyDate ? '#C52A3A' : '' }}>
                            Datum narození
                                <input 
                                    type='date' 
                                    className='id-input' 
                                    style={{ border: isDateError.attorneyDate ? 'solid 1px #C52A3A' : '' }}
                                    value={currentApplicant?.dateOfBirth ?? ''} 
                                    onChange={(e) => setCurrentApplicant(state => ({ ...state, dateOfBirth: e.target.value }))} 
                                    onBlur={(e) => {
                                        const validatedDate = dateValidation(e.target.value); 
                                        validatedDate && setCurrentApplicant(state => ({ ...state, dateOfBirth: '' }));
                                        setIsDateError(state => ({...state, attorneyDate: validatedDate}));
                                    }}
                                />
                            </label>
                            {isDateError.attorneyDate && <Container className='mt-2' style={{color: '#C52A3A', fontSize: '0.751rem'}}>
                                <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                            Nesprávný formát vyplnění, zkuste to znovu.
                            </Container>}
                        </Col>
                        
                        <label className='d-flex flex-column mb-4 label'>
                            Státní příslušnost
                            <Row>
                                <Col xs={12} md={6}>
                                    <select type='select' value={currentApplicant.nationality || ''}
                                        style={{appearance: 'none', background: `url(${arrowDownBlue}) no-repeat right .8rem center`, padding: '.5rem 2rem .5rem .8rem', backgroundColor: 'white', borderRadius: '3px', border: 'solid 1px #DDDDDD'}}
                                        onChange={(e) => setCurrentApplicant(state => ({ ...state, nationality: e.target.value }))}
                                        className='id-input2'
                                    >
                                        <option value={''}>Vyberte</option>
                                        {COUNTRIES.map((country) => (
                                            <option key={country.value} value={country.value}>{country.label}</option>
                                        ))}
                                    </select>
                                </Col>
                            </Row>
                        </label>
                        <label className='d-lg-flex align-items-end mb-4 label'>
                            <Row className='d-flex flex-column mb-3 mb-md-0 me-md-4'>
                                Funkce nebo pověření
                                <input type='text' className='id-input with-text' value={currentApplicant.mandate} onChange={(e) => setCurrentApplicant(state => ({ ...state, mandate: e.target.value }))} />
                            </Row>
                            <p className='mt-md-2 input-help-text'>Je nutné doložit příslušný doklad o pověření.</p>
                        </label>
                        <Col className='mb-4' md={6}>
                            <label className='d-flex flex-column label' style={{ color: isDateError.mandateFromDate ? '#C52A3A' : '' }}>
                            Pověření jednat od
                                <input 
                                    type='date' 
                                    className='id-input' 
                                    style={{ border: isDateError.mandateFromDate ? 'solid 1px #C52A3A' : '' }}
                                    value={currentApplicant?.mandateFrom ?? ''} 
                                    onChange={(e) => setCurrentApplicant(state => ({ ...state, mandateFrom: e.target.value }))} 
                                    onBlur={(e) => {
                                        const validatedDate = dateValidation(e.target.value); 
                                        validatedDate && setCurrentApplicant(state => ({ ...state, mandateFrom: '' }));
                                        setIsDateError(state => ({...state, mandateFromDate: validatedDate}));
                                    }}
                                />
                            </label>
                            {isDateError.mandateFromDate && <Container className='mt-2' style={{color: '#C52A3A', fontSize: '0.751rem'}}>
                                <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                            Nesprávný formát vyplnění, zkuste to znovu.
                            </Container>}
                        </Col>
                        <Col className='' md={6}>
                            <label className='d-flex flex-column label' style={{ color: isDateError.mandateTODate ? '#C52A3A' : '' }}>
                            Pověření jednat do
                                <input 
                                    type='date' 
                                    className='id-input' 
                                    style={{ border: isDateError.mandateTODate ? 'solid 1px #C52A3A' : '' }}
                                    value={currentApplicant?.mandateTO ?? ''} 
                                    onChange={(e) => setCurrentApplicant(state => ({ ...state, mandateTO: e.target.value }))} 
                                    onBlur={(e) => {
                                        const validatedDate = dateValidation(e.target.value); 
                                        validatedDate && setCurrentApplicant(state => ({ ...state, mandateTO: '' }));
                                        setIsDateError(state => ({...state, mandateTODate: validatedDate}));
                                    }}
                                />
                            </label>
                            {isDateError.mandateTODate && <Container className='mt-2' style={{color: '#C52A3A', fontSize: '0.751rem'}}>
                                <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                            Nesprávný formát vyplnění, zkuste to znovu.
                            </Container>}
                        </Col>
                        
                    </form>
                </Row>}
                
                {/* POVĚŘENÁ OSOBA */}
                {((currentApplicant.isFOBusiness && currentApplicant.includeRepresentative))  && <Row className='border p-4 row-wrapper'>
                    <h3 className='p-0 id-title'>Osoba pověřená jednat jménem fyzické osoby podnikající</h3>
                    <form className='p-0'>
                        <label className='d-lg-flex align-items-end mb-4 label'>
                            <Row className='d-flex flex-column mb-3 mb-md-0 me-md-4'>
                            Jméno
                                <input type='text' className='id-input with-text' value={currentApplicant.repreNames} onChange={(e) => setCurrentApplicant(state => ({ ...state, repreNames: e.target.value }))} />
                            </Row>
                            <p className='mt-md-2 input-help-text'>Uveďte všechna jména</p>
                        </label>
                        <label className='d-flex flex-column mb-4 label'>
                        Příjmení
                            <input type='text' className='id-input' value={currentApplicant.repreLastName} onChange={(e) => setCurrentApplicant(state => ({ ...state, repreLastName: e.target.value }))} />
                        </label>
                        <label className='d-lg-flex align-items-end mb-4 label'>
                            <Row className='d-flex flex-column mb-3 mb-md-0 me-md-4'>
                            Rodné příjmení
                                <input type='text' className='id-input with-text' value={currentApplicant.repreLastNameOrigin} onChange={(e) => setCurrentApplicant(state => ({ ...state, repreLastNameOrigin: e.target.value }))} />
                            </Row>
                            <p className='mt-md-2 input-help-text'>Vyplňte pouze v případě, že rodné příjmení je odlišné od příjmení</p>
                        </label>
                        <Col className='mb-4' md={6}>
                            <label className='d-flex flex-column label' style={{ color: isDateError.FOBusinessDate ? '#C52A3A' : '' }}>
                        Datum narození
                                <input 
                                    type='date'
                                    className='id-input'
                                    style={{ border: isDateError.FOBusinessDate ? 'solid 1px #C52A3A' : '' }}
                                    value={currentApplicant?.repreDateOfBirth ?? ''}
                                    onChange={(e) => setCurrentApplicant(state => ({ ...state, repreDateOfBirth: e.target.value }))}
                                    onBlur={(e) => {
                                        const validatedDate = dateValidation(e.target.value); 
                                        validatedDate && setCurrentApplicant(state => ({ ...state, repreDateOfBirth: '' }));
                                        setIsDateError(state => ({...state, FOBusinessDate: validatedDate}));
                                    }}
                                />
                            </label>
                            {isDateError.FOBusinessDate && <Container className='mt-2' style={{color: '#C52A3A', fontSize: '0.751rem'}}>
                                <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                            Nesprávný formát vyplnění, zkuste to znovu.
                            </Container>}
                        </Col>
                        <label className='d-flex flex-column mb-4 label'>
                                Státní příslušnost
                            <Row>
                                <Col xs={12} md={6}>
                                    <select type='select' value={currentApplicant.repreNationality || ''}
                                        style={{appearance: 'none', background: `url(${arrowDownBlue}) no-repeat right .8rem center`, padding: '.5rem 2rem .5rem .8rem', backgroundColor: 'white', borderRadius: '3px', border: 'solid 1px #DDDDDD'}}
                                        onChange={(e) => setCurrentApplicant(state => ({ ...state, repreNationality: e.target.value }))}
                                        className='id-input2'
                                    >
                                        <option value={''}>Vyberte</option>
                                        {COUNTRIES.map((country) => (
                                            <option key={country.value} value={country.value}>{country.label}</option>
                                        ))}
                                    </select>
                                </Col>
                            </Row>
                        </label>
                        <label className='d-lg-flex align-items-end mb-4 label'>
                            <Row className='d-flex flex-column mb-3 mb-md-0 me-md-4'>
                            Funkce nebo pověření
                                <input type='text' className='id-input with-text' value={currentApplicant.mandate} onChange={(e) => setCurrentApplicant(state => ({ ...state, mandate: e.target.value }))} />
                            </Row>
                            <p className='mt-md-2 input-help-text'>Je nutné doložit příslušný doklad o pověření.</p>
                        </label>
                        <Col className='mb-4' md={6}>
                            <label className='d-flex flex-column label' style={{ color: isDateError.businessMandateFromDate ? '#C52A3A' : '' }}>
                        Pověření jednat od
                                <input
                                    type='date'
                                    className='id-input'
                                    style={{ border: isDateError.businessMandateFromDate ? 'solid 1px #C52A3A' : '' }}
                                    value={currentApplicant?.mandateFrom ?? ''}
                                    onChange={(e) => setCurrentApplicant(state => ({ ...state, mandateFrom: e.target.value }))}
                                    onBlur={(e) => {
                                        const validatedDate = dateValidation(e.target.value); 
                                        validatedDate && setCurrentApplicant(state => ({ ...state, mandateFrom: '' }));
                                        setIsDateError(state => ({...state, businessMandateFromDate: validatedDate}));
                                    }}
                                />
                            </label>
                            {isDateError.businessMandateFromDate && <Container className='mt-2' style={{color: '#C52A3A', fontSize: '0.751rem'}}>
                                <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                            Nesprávný formát vyplnění, zkuste to znovu.
                            </Container>}
                        </Col>
                        
                        <Col className='' md={6}>
                            <label className='d-flex flex-column label' style={{ color: isDateError.businessMandateTODate ? '#C52A3A' : '' }}>
                        Pověření jednat do
                                <input
                                    type='date'
                                    className='id-input'
                                    style={{ border: isDateError.businessMandateTODate ? 'solid 1px #C52A3A' : '' }}
                                    value={currentApplicant?.mandateTO ?? ''}
                                    onChange={(e) => setCurrentApplicant(state => ({ ...state, mandateTO: e.target.value }))}
                                    onBlur={(e) => {
                                        const validatedDate = dateValidation(e.target.value); 
                                        validatedDate && setCurrentApplicant(state => ({ ...state, mandateTO: '' }));
                                        setIsDateError(state => ({...state, businessMandateTODate: validatedDate}));
                                    }}
                                />
                            </label>
                            {isDateError.businessMandateTODate && <Container className='mt-2' style={{color: '#C52A3A', fontSize: '0.751rem'}}>
                                <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                            Nesprávný formát vyplnění, zkuste to znovu.
                            </Container>}
                        </Col>
                        
                    </form>
                </Row>}

                {/* DORUČOVACÍ ADRESA STEJNÁ */}
                {!currentApplicant.isPO  && <Row className='row-wrapper'>
                    <form className='p-0'>
                        <label className='id-checkbox-label'>
                            <input type='checkbox' className='id-checkbox-input' checked={currentApplicant.shippingSameAsPr}
                                onChange={(e) => setCurrentApplicant(state => ({ ...state, shippingSameAsPr: e.target.checked }))}
                            />
                        Doručovací adresa je stejná jako adresa {currentApplicant.isFO ? 'trvalého pobytu' : 'místa podnikání'}
                        </label>
                    </form>
                </Row>}


                {/* DORUČOVACÍ ADRESA */}
                {(!currentApplicant.shippingSameAsPr && !currentApplicant.isPO) ? (<Row className='border p-4 row-wrapper'>
                    <h3 className='p-0 id-title'>Doručovací adresa</h3>
                    <form className='p-0'>
                        <label className='d-flex flex-column mb-4 label'>
                        Obec
                            <input type='text' className='id-input' value={currentApplicant.shippingAddress.city} onChange={(e) => setCurrentApplicant(state => ({ ...state, shippingAddress: {...state.shippingAddress, city: e.target.value}}))} />
                        </label>
                        <label className='d-flex flex-column mb-4 label'>
                        Část obce
                            <input type='text' className='id-input' value={currentApplicant.shippingAddress?.cityPart} onChange={(e) => setCurrentApplicant(state => ({ ...state, shippingAddress: {...state.shippingAddress, cityPart: e.target.value}}))} />
                        </label>
                        <label className='d-flex flex-column mb-4 label'>
                        Ulice
                            <input type='text' className='id-input' value={currentApplicant.shippingAddress?.street} onChange={(e) => setCurrentApplicant(state => ({ ...state, shippingAddress: {...state.shippingAddress, street: e.target.value}}))} />
                        </label>
                        <label className='d-lg-flex align-items-end mb-4 label'>
                            <Row className='d-flex flex-column mb-3 mb-md-0 me-md-4'>
                            Číslo popisné
                                <input type='text' className='id-input with-text' value={currentApplicant.shippingAddress?.descNum} onChange={(e) => setCurrentApplicant(state => ({ ...state, shippingAddress: {...state.shippingAddress, descNum: e.target.value}}))} />
                            </Row>
                            <p className='mt-md-2 input-help-text'>Pokud je místo čísla popisného přiděleno číslo evidenční, uveďte před číslem písmeno E.</p>
                        </label>
                        <label className='d-flex flex-column mb-4 label'>
                        Číslo orientační
                            <input type='text' className='id-input' value={currentApplicant.shippingAddress?.orientNum} onChange={(e) => setCurrentApplicant(state => ({ ...state, shippingAddress: {...state.shippingAddress, orientNum: e.target.value}}))} />
                        </label>
                        <label className='d-flex flex-column label'>
                        PSČ
                            <input
                                // {...(focused.shipZip && { focused: 'true' })}
                                style={{border: focused.shipZip && currentApplicant.shippingAddress?.zip?.toString().trim()?.length > 0  &&
                                    !zipPattern.test(currentApplicant.shippingAddress.zip?.toString().trim().replace(/\s+/g, ''))
                                    ? '#C78300 solid 1px'
                                    : ''}}
                                type='text' 
                                className='id-input'
                                value={currentApplicant.shippingAddress?.zip} 
                                onChange={(e) => setCurrentApplicant(state => ({ ...state, shippingAddress: {...state.shippingAddress, zip: e.target.value}}))} 
                                onBlur={() => {
                                    handleFocused('shipZip');
                                }}
                            />
                            {focused.shipZip && currentApplicant.shippingAddress?.zip?.toString().trim()?.length > 0  &&
                                    !zipPattern.test(currentApplicant.shippingAddress.zip?.toString().trim().replace(/\s+/g, '')) &&
                                    <Container style={{color: '#C78300', fontSize: '0.751rem', marginTop: '.5rem'}}>
                                        <img src={warningYellow} alt='warning icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                        Chyba formátu &quot;PSČ&quot; může obsahovat pouze pět číslic.
                                    </Container>}
                        </label>
                    </form>
                </Row>) : null }

                {/* KONTAKTNÍ ÚDAJE */}
                <Row className='row-wrapper'>
                    <form className='p-0'>
                        <label className='d-flex flex-column mb-4 label'>
                        Telefon (nepovinné)                            
                            <input
                                // {...(focused.phone && { focused: 'true' })}
                                style={{border: focused.phone && currentApplicant.phone?.toString().trim()?.length > 0  &&
                                    !telPattern.test(currentApplicant.phone?.toString().trim().replace(/\s+/g, ''))
                                    ? '#C78300 solid 1px'
                                    : ''}}
                                type='tel' 
                                className='id-input' 
                                value={currentApplicant.phone} 
                                onChange={(e) => setCurrentApplicant(state => ({ ...state, phone: e.target.value }))} 
                                onBlur={() => {
                                    handleFocused('phone');
                                }}
                            />
                            {focused.phone && currentApplicant.phone?.toString().trim()?.length > 0  &&
                                    !telPattern.test(currentApplicant.phone?.toString().trim().replace(/\s+/g, '')) &&
                                    <Container style={{color: '#C78300', fontSize: '0.751rem', marginTop: '.5rem'}}>
                                        <img src={warningYellow} alt='warning icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                        Chyba formátu &quot;Telefon&quot; musí obsahovat alespoň devět číslic.
                                    </Container>}
                        </label>
                        <label className='d-flex flex-column mb-4 label'>
                        E-mail (nepovinné)                            
                            <input 
                                // {...(focused.email && { focused: 'true' })}
                                style={{border: focused.email && currentApplicant.email?.toString().trim()?.length > 0  &&
                                    !emailPattern.test(currentApplicant.email?.toString())
                                    ? '#C78300 solid 1px'
                                    : ''}}
                                type='email'
                                className='id-input'
                                value={currentApplicant.email}
                                onChange={(e) => setCurrentApplicant(state => ({ ...state, email: e.target.value }))} 
                                onBlur={() => {
                                    handleFocused('email');
                                }}
                            />
                            {focused.email && currentApplicant.email?.toString().trim()?.length > 0  &&
                                    !emailPattern.test(currentApplicant.email?.toString()) &&
                                    <Container style={{color: '#C78300', fontSize: '0.751rem', marginTop: '.5rem'}}>
                                        <img src={warningYellow} alt='warning icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                        Chyba formátu &quot;E-mail&quot; email nemá správný formát.
                                    </Container>}
                        </label>
                        <label className='d-flex flex-column label'>
                        ID datové schránky
                            <input type='text' className='id-input' value={currentApplicant.idDataBox} onChange={(e) => setCurrentApplicant(state => ({ ...state, idDataBox: e.target.value }))} />
                        </label>
                    </form>
                </Row>

                {(canRequestPlnaMocFifth && !isDocumentationPage) && <Row className='row-wrapper'>
                    <UploadedAttachments
                        category={PLNA_MOC_FIFTH}
                        attachments={plnaMocForZadatelRepreAttachment}
                        setUploads={setZadatelAttachments}
                        applicantArr={applicantArr}
                        currentApplicant={currentApplicant}
                        setCurrentApplicant={setCurrentApplicant}
                    />
                    <h3 className='p-0 mb-2 id-title'>{currentApplicant.isPO?'Doklad o oprávnění jednat jménem právnické osoby':'Doklad o oprávnění jednat jménem fyzické osoby podnikající'}</h3>
                    <p className='p-0 mb-3 sub-title'>{currentApplicant.isPO?'Je nutné doložit příslušný doklad o oprávnění v případě, že oprávnení jednat jménem právnické osoby není možné ověřit z veřejných rejstříků':'Je nutné doložit příslušný doklad o oprávnění v případě, že oprávnení jednat jménem fyzické osoby podnikající není možné ověřit z veřejných rejstříků'}</p>
                    <FileUpload handleFileInput={handleFileInput} category={PLNA_MOC_FIFTH}/>
                    {err.plnaMocForZadatelRepreAttachmentError && <Col xs={{span: 12, order: 2}} md={{span: 6, order: 3}} className='mt-2'>
                        <p className='d-flex align-items-center p-0 warning-text'>
                            <img src={errorIcon} alt='warning' className='me-2' style={{height: '1.1rem', width: '1.1rem'}}/>
                            Bez nahrané plné moci není možné pokračovat
                        </p>
                    </Col>}
                </Row>}

                {/* PLNÁ MOC */}
                {!isDocumentationPage && <Row className='row-wrapper'>
                    <UploadedAttachments
                        category={PLNA_MOC_SECOND}
                        attachments={plnaMocForZadatelAttachment}
                        setUploads={setZadatelAttachments}     
                        applicantArr={applicantArr}
                        currentApplicant={currentApplicant}
                        setCurrentApplicant={setCurrentApplicant}
                    />
                    <h3 className='p-0 mb-2 id-title'>Plná moc k zastupování</h3>
                    <p className='p-0 mb-3 sub-title'>{`Nahrajte plnou moc na základě které můžete zastupovat ${capitalizedApplicant}e.`} Můžete využít <Link to="/doc/PLNÁ MOC_DSR (002).docx" target="_blank" download='Plná moc k zastupování'>náš vzor</Link>.</p>
                    <FileUpload handleFileInput={handleFileInput} category={PLNA_MOC_SECOND} />
                    {err.plnaMocForZadatelAttachmentError &&  <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 3 }} className='mt-2'>
                        <p className='d-flex align-items-center p-0 warning-text'>
                            <img src={errorIcon} alt='warning' className='me-2' style={{ height: '1.1rem', width: '1.1rem' }} />
                            Bez nahrané plné moci není možné pokračovat
                        </p>
                    </Col>}
                </Row>}
                
            </Modal.Body>
            <Modal.Footer bsPrefix='d-flex px-sm-5 modal-footer-custom'>
                <Row className='button-wrapper'>
                    <Col className='d-flex flex-column flex-lg-row button-wrapper--inner' xs={12} lg={6}>
                        <button
                            disabled={!canProceed} 
                            className={`mb-3 mb-lg-0 me-lg-3 modal-button modal-button--filled ${!canProceed ? 'disabled-filled' : ''}`}
                            onClick={() => { 
                                if (!isAttorneyForCurrentUserAttached && !isDocumentationPage) {
                                    setErr(prev => ({...prev, plnaMocForZadatelAttachmentError: true}));
                                    return;
                                }
                                if (canRequestPlnaMocFifth && !isAttorneyRepreForCurrentUserAttached && !isDocumentationPage) {
                                    setErr(prev => ({...prev, plnaMocForZadatelRepreAttachmentError: true}));
                                    return;
                                }

                                handleSaveApplicant(
                                    currentApplicant, 
                                    applicantArr, 
                                    setCurrentApplicant, 
                                    setShow, 
                                    setApplicantArr,
                                ); }}
                        >
                            Přidat
                        </button>
                        <button
                            disabled={!canProceed}
                            className={`modal-button modal-button--filled ${!canProceed ? 'disabled-filled' : ''}`}
                            onClick={() => {
                                if (!isAttorneyForCurrentUserAttached && !isDocumentationPage) {
                                    setErr(prev => ({...prev, plnaMocForZadatelAttachmentError: true}));
                                    return;
                                }
                                if (canRequestPlnaMocFifth && !isAttorneyRepreForCurrentUserAttached && !isDocumentationPage) {
                                    setErr(prev => ({...prev, plnaMocForZadatelRepreAttachmentError: true}));
                                    return;
                                }
                                
                                handleSaveApplicant(
                                    currentApplicant, 
                                    applicantArr, 
                                    setCurrentApplicant, 
                                    setShow, 
                                    setApplicantArr,
                                    titleRef,
                                ); }}
                        >
                            Přidat další
                        </button>
                    </Col>
                    <Col className='d-flex justify-content-end'>
                        <button 
                            className='modal-button' 
                            onClick={() => {
                                cancelAddPerson(
                                    currentApplicant,
                                    setZadatelAttachments,
                                    setShow,
                                    setCurrentApplicant,
                                    applicantArr
                                );
                                setFocused(initialFocusState);
                            }}>
                            Zrušit
                        </button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );
};

ApplicantMoreModal.propTypes = {
    show: PropTypes.bool,
    setShow: PropTypes.func,
    currentApplicant: PropTypes.objectOf(String),
    applicantArr: PropTypes.arrayOf(Object),
    setApplicantArr: PropTypes.func,
    setCurrentApplicant: PropTypes.func,
    handleFileInput: PropTypes.func,
    plnaMocForZadatelAttachment: PropTypes.arrayOf(Object),
    plnaMocForZadatelRepreAttachment: PropTypes.arrayOf(Object),
    setZadatelAttachments: PropTypes.func,
    applicant: PropTypes.string,
    applicantType: PropTypes.string,
    isDocumentation: PropTypes.bool,
};

export default ApplicantMoreModal;
