/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { GovButton } from '@gov-design-system-ce/react';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

const UrednikCancelModal = ({
    cancelModalOpen,
    setCancelModalOpen,
    confirmOperation,
}) => {
    const [loading, setLoading] = useState(false);
    const handleClose = () => {
        setCancelModalOpen(false);
    };

    const handleConfirm = async () => {
        try {
            setLoading(true);
            await confirmOperation();
        } catch(error) {
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal show={cancelModalOpen} onHide={handleClose} size='md' centered>
            <Modal.Header className='modal-header--radius modal-background' bsPrefix='d-flex justify-content-between align-items-center px-sm-5 modal-header-custom modal-background'>
                <Modal.Title bsPrefix='modal-title'>Opravdu zrušit rozpracovanou dokumentaci?</Modal.Title>
            </Modal.Header>

            <Modal.Body className='modal-background' bsPrefix='px-sm-5 modal-body-custom d-flex flex-column'>
                <p className='pb-5 pridat-text label deletion'>Rozpracovaná dokumentace bude smazána bez možnosti obnovení. Určitě chcete dokumentaci zrušit?</p>
            </Modal.Body>

            <Modal.Footer className='modal-footer--radius modal-background' bsPrefix='d-flex px-sm-5 modal-footer-custom align-items-center justify-content-between'>
                <GovButton
                    nativeType='button'
                    variant='error'
                    size='m'
                    onGov-click={handleConfirm}
                    disabled={loading}
                    loading={loading}
                >
                    Zrušit
                </GovButton>

                <GovButton
                    nativeType='button'
                    variant='primary'
                    type='outlined'
                    size='m'
                    onGov-click={handleClose}
                >
                    Zpět
                </GovButton>
            </Modal.Footer>
        </Modal>
    );
};

export default UrednikCancelModal;