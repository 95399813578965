import React, { useState }/* , {useState} */ from 'react';
import { HeaderSection, DokumentaceMainSection } from '../components';
import { Container } from 'react-bootstrap';

const DokumentaceScreen = () => {
    const [isActive, setIsActive] = useState('block');

    return (
        <Container className='d-flex flex-column align-items-center' fluid>
            <HeaderSection headerText={'Zde můžete vložit projektovou dokumentaci nebo dokumentaci pro povolení záměru do evidence elektronických dokumentací'} page={'dokumentace'} mainHeader={'Dokumentace'} />
            <DokumentaceMainSection isActive={isActive} setIsActive={setIsActive} />
        </Container>);
};

export default DokumentaceScreen;