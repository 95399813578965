import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { unableToChange } from '../assets';

const UnableToChange = () => {
    return (
     
        <Container className='d-flex flex-column justify-content-center m-auto unauthorized-container'>
            <Row className='py-4 emoji-container d-flex align-items-center justify-content-center m-auto'><img className='emoji m-auto' src={unableToChange} alt="Přístup nepovolen" /></Row>
            <Row className='py-4 d-flex align-items-center justify-content-center'><h1 className='m-0 p-0 unauthorized-title'>Průvodní list již nelze měnit</h1></Row>
            <Row className='py-4 d-flex align-items-center justify-content-center'><p className='m-0 p-0 unauthorized-text w-50'>Průvodní list již nelze měnit, protože žádost, ke které je připojen, byla odeslána.</p></Row>
        </Container>
       
    );
};

export default UnableToChange;