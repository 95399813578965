import React from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import '../../css/zadosti.css';
import DokumentaceVersionCard from './DokumentaceVersionCard';

const DokumentaceVersionsNewest = ({ item }) => {
    return (
        <Row className='row-revert-x-margin'>
            <Col xs={12} className='row-revert-x-padding'>
                <h5 className='py-4 fw-500'>Nejnovější verze</h5>
            </Col>
            <Col xs={12} md={6} lg={4} className='row-revert-x-padding'>
                <DokumentaceVersionCard item={item} isNew />
            </Col>
        </Row>
    );
};

DokumentaceVersionsNewest.propTypes = {
    item: PropTypes.any,
};

export default DokumentaceVersionsNewest;