import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { GovButton } from '@gov-design-system-ce/react';
import { AuthContext } from 'react-oauth2-code-pkce';
import moment from 'moment';
import cs from 'moment/locale/cs';
import { CardContainer, CardHeader, CardFooter } from '../Card';
import { downloadDocumentVersion } from './utils/index.js';
import { isRequestOK } from '../../apiCalls/componentsApiCalls.js';

moment.locale('cs', cs);
export const formatedDate = (date) => {
    return date ? moment(date).format('LLL') : date;
};

const DokumentaceVersionCard = ({ item, isNew }) => {
    const { token } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);

    const date = formatedDate(item?.finishDate);
    const buttonType = isNew ? 'solid' : 'outlined';
    const containerClasses = isNew ? 'bg--gov-color-primary-100 border--gov-color-primary-400 w-auto' : 'w-auto';

    const handleClick = async (bppPid, token) => {
        if (!bppPid) {
            return;
        }

        try {
            setIsLoading(true);
            const response = await downloadDocumentVersion(bppPid, token);

            if (isRequestOK(response?.status)) {
                const blob = new Blob([response.data], { type: 'application/zip' });
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = `dokumentace_${bppPid}.zip`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };

    return (
        <CardContainer classNames={containerClasses}>
            <CardHeader>
                <h4 className='p-0 zadosti-title'>{date ?? ''}</h4>
                <p className='p-0 zadosti-id'>ID dokumentace: {item?.bppPid ?? ''}</p>
            </CardHeader>

            <CardFooter>
                <GovButton
                    nativeType='button'
                    className='w-100'
                    iconLeft='basic/file-earmark-arrow-down'
                    type={buttonType}
                    variant='primary'
                    expanded={true}
                    disabled={isLoading}
                    aria-disabled={isLoading}
                    loading={isLoading}
                    onGov-click={() => handleClick(item?.bppPid, token)}
                >
                    Stáhnout
                </GovButton>
            </CardFooter>
        </CardContainer>
    );
};

DokumentaceVersionCard.propTypes = {
    item: PropTypes.any,
    isNew: PropTypes.bool,
};

export default DokumentaceVersionCard;