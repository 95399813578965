import React from 'react';
import { stop } from '../../assets/index.js';
import '../../css/homePage.css';

const UrednikSendingError = () => {
    return (
        <div className='card-content card vh-100 d-flex justify-content-center align-items-center p-3 mt-5'>
            <img className='error-page--img' src={stop} alt='something went wrong'/>
            <p className='error-page--header-text'>Došlo k chybě.</p>
            <p className='error-page--secondary-text no-max-width no-margin'>
                Prosím zavřete tuto záložku prohlížeče a zopakujte celý proces.
            </p>
            <i>Error 500</i>
        </div>
    );
};

export default UrednikSendingError;