import React, { useEffect, useState, useContext, useImperativeHandle } from 'react';
import { Container, Row } from 'react-bootstrap';
import '../../css/zadostPovoleniStavby.css';
import '../../css/rozsah.css';
import {  shouldNotSave, useOnlyRequiredKeys, checkLimitAndInsertText } from '../../helperFunctions/helpers';
import PropTypes from 'prop-types';
import axios from 'axios';
import { AuthContext } from 'react-oauth2-code-pkce';
import { formSave, getFormById } from '../../apiCalls/formApiCalls';
import FormContext from '../../formContexts/FormContext';
import useFirstRender from '../CustomHooks/useFirstRender.jsx';
import usePrevious from '../CustomHooks/usePrevious.jsx';
import { CharCount } from '../../components/index.js';
import { errorIcon } from '../../assets/index.js';
import { useLocation, useNavigate } from 'react-router-dom';

const RozsahComponent06 = ({setCheckbox, urlPath}) => {
    const {intention, stepValue, validationRef, setStagesArr, stagesArr, saveFormRef, id} = useContext(FormContext);
    const error = stagesArr.find(stg => Number(stg.stage) === Number(stepValue))?.error ?? false;
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 700);
    const { token } = useContext(AuthContext);
    const [ focused, setFocused ] = useState(false);

    const [request, setRequest] = useState({
        'applicationId': id,
        form: {
            changeRequestLandUsage: true,
            changeRequestLandUsageFast: false,
            changeLandscape: false,
            changeMineralDeposits: false,
            changeManipulationArea: false,
            changeBurialGround: false,
            changeConditionsAreSetOut: false,
            changeAffectAbsorbWater: false,
            requestPublicUtility: null,
            descriptionOfChange: '',
        }
    });
    const firstRender = useFirstRender();
    const prevRequest = usePrevious(request);
    const [count, setCount] = useState({
        [0]: {count: 0, limit: 10000},
    });
    const isOneChangeLandUseSelected = [
        request.form.changeLandscape,
        request.form.changeMineralDeposits,
        request.form.changeManipulationArea,
        request.form.changeBurialGround,
        request.form.changeConditionsAreSetOut,
        request.form.changeAffectAbsorbWater,
    ].some((value) => value === true);

    useEffect(() => {
        function handleResize() {
            if(window.innerWidth <= 800) {
                setIsMobileView(true);
                return;
            } 

            setIsMobileView(false); 
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);

    }, []);

    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!id) {
            return;
        }
        const source = axios.CancelToken.source();
        (async () => {
            const response = await getFormById(id, token, source, intention, null, pathname, navigate);

            if ((200 <= response?.status) && (response?.status < 300)) {

                const loadedData = response.data?.buildApplicationForms?.[0];

                if (loadedData) {
                    const formStructure = request.form;
                    const filteredDataToSave = useOnlyRequiredKeys(formStructure, loadedData);
                    setRequest(state => ({ ...state, form: {...state.form, 
                        ...filteredDataToSave,
                        changeRequestLandUsage: loadedData.changeRequestLandUsage ?? true, 
                        changeRequestLandUsageFast: loadedData.changeRequestLandUsageFast ?? false,
                        requestPublicUtility: loadedData.requestPublicUtility ?? false,
                    } }));
                }

            } else {
                console.log('ID NOT YET IN DB');
            }
        })();

        return () => {
            source.cancel('Operation canceled by the user.');
        };
    }, []);

    useEffect(() => {
        if (request.form?.requestPublicUtility === null) {
            return;
        }

        setCheckbox({
            first: request.form.requestPublicUtility ?? false,
        });
    }, [request.form.requestPublicUtility]);

    const saveChanges = async () => {
        const source = axios.CancelToken.source();
        await formSave(request, token, urlPath, source);
    };

    useEffect(() => {
        if (shouldNotSave(prevRequest, request, firstRender)) {
            return;
        }

        saveChanges();
    }, [request.form]);

    useImperativeHandle(saveFormRef, () => ({
        saveForm: () => saveChanges()
    }));

    useImperativeHandle(validationRef, () => ({
        validate() {
            let err = false;

            if((!request.form?.descriptionOfChange?.trim()))
            {
                err = true;
            }

            if(!isOneChangeLandUseSelected) {
                err = true;
            }

            setStagesArr(prev => [...prev].map(stg => {
                if (Number(stg.stage) === Number(stepValue)) {
                    
                    return {...stg, error: err ?? false, };
                }
                return stg;
            }));

            return !err;
        }
    }));

    const hasError = (error && !request.form?.descriptionOfChange?.trim()) || (focused && !request.form?.descriptionOfChange?.trim());

    return (
        <Container className='py-0 d-flex row align-items-center rozsahContainer' fluid>
            <Row className='row-wrapper'>
                <p className='p-0 title primary'>{`${stepValue}. Rozsah`}</p>
            </Row>

            <Row className='row-wrapper'>
                <p className='p-0 mb-4 title secondary'>Žádám o změnu využití území</p>
                <form className='p-0'>
                    <label className={`ls-1p25 p-0 pb-2 d-flex align-items-start ${!isMobileView ? 'w-50' : 'w-100'}`}>
                        <input
                            className='me-3'
                            type="radio"
                            name='zadost'
                            checked={request.form.changeRequestLandUsage}
                            onChange={()=>{
                                setRequest(state => ({...state, form: {...state.form, changeRequestLandUsage: true, changeRequestLandUsageFast: false }}));
                            }}
                        />
                            v řízení o povolení změny využití území
                    </label>
                    <div className='d-flex justify-content-between'>
                        <label className={`ls-1p25 p-0 d-flex align-items-start ${!isMobileView ? 'w-50' : 'w-100'}`}>
                            <input
                                className='me-3'
                                type="radio" 
                                name='zadost'
                                checked={request.form.changeRequestLandUsageFast} 
                                onChange={()=>{
                                    setRequest(state => ({...state, form: {...state.form, changeRequestLandUsage: false, changeRequestLandUsageFast: true }}));
                                }}
                            />
                            ve zrychleném řízení o povolení změny využití území
                        </label>
                        <span className={`fst-italic ${!isMobileView ? 'w-50' : 'w-100'}`}>Pokud žádáte o vydání povolení ve zrychleném řízení, doložte souhlas všech účastníků řízení se záměrem. Souhlas s navrhovaným záměrem musí být vyznačen na situačním výkresu dokumentace a musí obsahovat identifikační údaje a podpis účastníků řízení.</span>
                    </div>
                </form>
            </Row>

            <Row className='row-wrapper'>
                <div className='d-flex justify-content-between p-0 mb-4'>
                    <p className={`title third ${!isMobileView ? 'w-50' : 'w-100'}`}>Změna využití území</p>
                    <span className={`fst-italic pl-3 ${!isMobileView ? 'w-50' : 'w-100'}`}>Viz § 213 stavebního zákona</span>
                </div>
                <form className='p-0'>
                    <label className={`ls-1p25 p-0 mb-2 d-flex align-items-start ${!isMobileView ? 'w-50' : 'w-100'}`}>
                        <input
                            className='me-3'
                            type="checkbox"
                            checked={request.form.changeLandscape}
                            onChange={(e) => {
                                setRequest(state => ({...state, form: {...state.form, changeLandscape: e.target.checked }}));
                            }}
                        />
                            terénní úprava                
                    </label>                  
                    <label className={`ls-1p25 p-0 mb-2 d-flex align-items-start ${!isMobileView ? 'w-50' : 'w-100'}`}>
                        <input
                            className='me-3'
                            type="checkbox"
                            checked={request.form.changeMineralDeposits}
                            onChange={(e) => {
                                setRequest(state => ({...state, form: {...state.form, changeMineralDeposits: e.target.checked }}));
                            }}
                        />
                            stanovení prostoru pro dobývání ložisek nerostů, pro která se nestanoví dobývací prostor podle horního zákona
                    </label>
                    <label className={`ls-1p25 p-0 mb-2 d-flex align-items-start ${!isMobileView ? 'w-50' : 'w-100'}`}>
                        <input
                            className='me-3'
                            type="checkbox"
                            checked={request.form.changeManipulationArea}
                            onChange={(e) => {
                                setRequest(state => ({...state, form: {...state.form, changeManipulationArea: e.target.checked }}));
                            }}
                        />
                            odstavná, manipulační, prodejní, skladová nebo výstavní plocha                
                    </label>                  
                    <label className={`ls-1p25 p-0 mb-2 d-flex align-items-start ${!isMobileView ? 'w-50' : 'w-100'}`}>
                        <input
                            className='me-3'
                            type="checkbox"
                            checked={request.form.changeBurialGround}
                            onChange={(e) => {
                                setRequest(state => ({...state, form: {...state.form, changeBurialGround: e.target.checked }}));
                            }}
                        />
                           pohřebiště
                    </label>
                    <label className={`ls-1p25 p-0 mb-2 d-flex align-items-start ${!isMobileView ? 'w-50' : 'w-100'}`}>
                        <input
                            className='me-3'
                            type="checkbox"
                            checked={request.form.changeConditionsAreSetOut}
                            onChange={(e) => {
                                setRequest(state => ({...state, form: {...state.form, changeConditionsAreSetOut: e.target.checked }}));
                            }}
                        />
                            změna druhu pozemku, nebo způsobu využití pozemku, pokud podmínky nejsou stanoveny schválenými pozemkovými úpravami                
                    </label>                  
                    <label className={`ls-1p25 p-0 mb-2 d-flex align-items-start ${!isMobileView ? 'w-50' : 'w-100'}`}>
                        <input
                            className='me-3'
                            type="checkbox"
                            checked={request.form.changeAffectAbsorbWater}
                            onChange={(e) => {
                                setRequest(state => ({...state, form: {...state.form, changeAffectAbsorbWater: e.target.checked }}));
                            }}
                        />
                            úprava pozemku, která má vliv na schopnost vsakování vody
                    </label>
                    {
                        error
                        &&
                        !isOneChangeLandUseSelected
                        &&
                        <Container className='mt-1' style={{color: '#C52A3A', fontSize: '0.751rem', marginBottom: '.5rem'}}>
                            <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                            Bez zaškrtnutí alespoň jednoho pole není možné pokračovat.
                        </Container>
                    }
                </form>
            </Row>

            <Row className='row-wrapper'>
                <p className='p-0 mb-4 title third'>Součástí žádosti je</p>
                <form className='p-0'>
                    <label className={`ls-1p25 p-0 d-flex align-items-start ${!isMobileView ? 'w-50' : 'w-100'}`}>
                        <input
                            className='me-3'
                            type="checkbox"
                            checked={request.form.requestPublicUtility ?? false}
                            onChange={(e) => {
                                setRequest(state => ({...state, form: {...state.form, requestPublicUtility: e.target.checked }}));
                            }}
                        />
                            žádost o změnu nebo zrušení povolení v řízení o povolení veřejně prospěšné stavby nebo veřejně prospěšného opatření
                    </label>
                </form>
            </Row>

            <Row className=''>
                <p className='p-0 mb-4 title third'>Charakteristika žádosti</p>
                <form className='p-0'>
                    <label className='d-flex flex-column p-0 textarea-input' style={{color: hasError ? '#C52A3A' : ''}}>
                    Popis předpokládané změny využití území
                        <Row className='position-relative textarea-with-count-container'>
                            <textarea
                                className='textarea-with-count mt-2 p-2'
                                onChange={(e) => { checkLimitAndInsertText(e.target, 0, count, setCount); }}    
                                {...(focused && {focused: 'true'})}
                                rows="5"
                                defaultValue={request.form.descriptionOfChange}
                                style={{border: hasError ? 'solid #C52A3A 1.5px' : '', borderRadius: hasError ? '3px' : ''}}
                                onBlur={(e) => {setRequest(state => ({ ...state, form: { ...state.form, descriptionOfChange: e.target.value } }));
                                    setFocused(true);
                                }}
                            />
                            <CharCount 
                                value={request.form.descriptionOfChange} setCount={setCount} propToUpdate={0} count={count[0].count} limit={count[0].limit}
                            />
                        </Row>
                    </label>
                </form>
            </Row>
        </Container>
    );
};

RozsahComponent06.propTypes = {
    
    setCheckbox: PropTypes.func,
    urlPath: PropTypes.string.isRequired,
};

export default RozsahComponent06;