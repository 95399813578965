import React, { useState, useContext, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { ZamerComponent09, FormProgress, ZadatelComponent09, Dokumentace, PovoleniComponent09, RozsahComponent09, VyjimkaComponent09, PrilohyComponent09, PrijemceComponent09, KontrolaComponent09 } from '../index.js';
import { AuthContext } from 'react-oauth2-code-pkce';
import { formSave } from '../../apiCalls/formApiCalls.js';
import { FORM_09_INTENTION } from '../../constants/sharedConstants.js';
import FormContext from '../../formContexts/FormContext.js';
import { getUploadedPruvodniList, isDokumentace, isPrilohy, sendAttachmentRequest, sendDocumentationRequest } from '../../helperFunctions/helpers.js';
import axios from 'axios';

const Form09MainSection = ({
    stage,
    checkbox,
    setCheckbox,
    attachments,
    setAttachments,
    isMobileView,
    documents,
    setDocuments,
}) => {
    const [loading, setLoading] = useState(true);
    const { token } = useContext(AuthContext);
    let { id: urlId } = useParams();
    const urlPath = 'quick-save-api/build-application-09/save';
    const stepsRef = useRef([]);
    const [foundApplicantIndex, setFoundApplicantIndex] = useState(null);
    const [applicantArr, setApplicantArr] = useState([]);
    const {setId, stepValue, stagesArr, id, setWaitingForPruvodniList} = useContext(FormContext);
    const documentsToSaveManually = React.useMemo(() => 
        [...(Object.values(documents || {})?.flat() || [])].filter(fileObj => (!fileObj.blobFile && !fileObj.abortController && !fileObj.message)), 
    [documents]);
    const attachmentsToSaveManually = React.useMemo(() => 
        [...(Object.values(attachments || {})?.flat()?.filter(el => !el.section?.startsWith('power_of_attorney')) || [])]
            .filter(fileObj => (!fileObj.blobFile && !fileObj.abortController && !fileObj.message)), 
    [attachments]);
    
    useEffect(() => {
        if (!urlId) {
            (async () => {
                let data = JSON.stringify({
                    'buildApplication': {
                        'title': FORM_09_INTENTION.title
                    }
                });

                const response = await formSave(data, token, urlPath);
                if ((200 <= response?.status) && (response?.status < 300)) {
                    console.log('START NOVE ZADOSTI 09', response.data);
                    setId(response.data);
                    setLoading(false);
                }
            })();
        }

        else {
            setId(urlId);
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (!documentsToSaveManually.length || isDokumentace(stepValue, stagesArr)) {
            return; 
        }
        const source = axios.CancelToken.source();
        const requestObject = sendDocumentationRequest(id, [...(Object.values(documents || {}) || []).flat().filter(el => !el.message), ...documentsToSaveManually]);
        if (requestObject) {
            formSave(requestObject, token, urlPath, source);
        }
        if (setWaitingForPruvodniList) setWaitingForPruvodniList(!getUploadedPruvodniList(documentsToSaveManually));
    }, [documentsToSaveManually.length]);

    useEffect(() => {
        if (!attachmentsToSaveManually.length || isPrilohy(stepValue, stagesArr)) {
            return; 
        }
        const source = axios.CancelToken.source();
        const requestObject = sendAttachmentRequest(id, [...(Object.values(attachments|| {}) || []).flat().filter(el => !el.message), ...attachmentsToSaveManually]);
        if (requestObject) {
            formSave(requestObject, token, urlPath, source);
        }
    }, [attachmentsToSaveManually.length]);

    return (
        <Container fluid className='d-flex justify-content-center px-3 pt-0 pb-3 p-lg-5'>
            <Row className='base-width'>
                <Col className='d-flex justify-content-center justify-content-md-end d-md-block d-lg-none mb-4 mb-lg-0'>
                    {isMobileView && 
                        <FormProgress
                            stepsRef={stepsRef}
                            isMobile={isMobileView}
                        />
                    }
                </Col>
                <Col xs={{ span: 12 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 8 }} className='pe-lg-3'>
                    {loading ? (<span>Načítám formulář...</span>) :
                        (<>
                            {stage === 1 && <ZamerComponent09
                                documents={documents}
                                setDocuments={setDocuments}
                                urlPath={urlPath}
                                setCheckbox={setCheckbox}
                            />}
                            {stage === 2 && <ZadatelComponent09
                                urlPath={urlPath}
                                stage={stage}
                                foundApplicantIndex={foundApplicantIndex}
                                applicantArr={applicantArr}
                                setApplicantArr={setApplicantArr}
                                setFoundApplicantIndex={setFoundApplicantIndex}
                            />}
                            {stage === 3 && <PovoleniComponent09
                                urlPath={urlPath}
                            />}
                            {stage === 4 && <Dokumentace

                                urlPath={urlPath}
                                documents={documents}
                                setDocuments={setDocuments}
                            />}
                            {stage === 5 && <RozsahComponent09
                                urlPath={urlPath}
                                setCheckbox={setCheckbox}
                            />}
                            {(checkbox.first && stage === 6) && <VyjimkaComponent09 urlPath={urlPath} />}
                            {((checkbox.first && stage === 7) || (!checkbox.first && stage === 6)) && <PrilohyComponent09
                                attachments={attachments}
                                setAttachments={setAttachments}
                                urlPath={urlPath}
                                stepsRef={stepsRef}
                                setFoundApplicantIndex={setFoundApplicantIndex}
                                applicantArr={applicantArr}
                            />}
                            {((checkbox.first && stage === 8) || (!checkbox.first && stage === 7)) && <PrijemceComponent09
                                urlPath={urlPath}
                            />}
                            {((checkbox.first && stage === 9) || (!checkbox.first && stage === 8)) && <KontrolaComponent09/>}
                        </>)}
                </Col>
                <Col lg={{ span: 4 }} xl={{ span: 4 }} className='d-flex justify-content-center justify-content-md-end d-none d-lg-block mb-md-5'>
                    {!isMobileView && 
                        <FormProgress
                            stepsRef={stepsRef}
                            isMobile={isMobileView}
                        />
                    }
                </Col>
            </Row>
        </Container>
    );
};

export default Form09MainSection;

Form09MainSection.propTypes = {
    stage: PropTypes.number.isRequired,
    checkbox: PropTypes.shape({
        'first': PropTypes.bool,
        'second': PropTypes.bool,
    }).isRequired,
    setCheckbox: PropTypes.func,

    progressIndicators: PropTypes.arrayOf(Number),
    setProgressIndicators: PropTypes.func,
    isMobileView: PropTypes.bool,
    documents: PropTypes.object,
    setDocuments: PropTypes.func,
};