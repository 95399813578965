import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import { closeErrorPanelRed, deleteFile, file, iconChange, reloadIcon, uploadedFile } from '../../assets/index.js';
import { ConfirmBeforeDeleteModal, Progress } from '../index.js';
import PropTypes from 'prop-types';
import useUpload from '../CustomHooks/useUpload.jsx';
import { cancelUpload, canReupload, convertBytes, handleDeleteUpload, remapAbortControllerOnError, shouldNotFetch, isTrackableForDokumentace, isTrackableForPrilohy } from '../../helperFunctions/helpers.js';
import { FORM_18_INTENTION, OTHER_DOCUMENTS, PLNA_MOC_FIFTH, PLNA_MOC_FOURTH, PLNA_MOC_SECOND, PLNA_MOC_THIRD, POWER_OF_ATTORNEY_FILE_MODEL, WARRANT } from '../../constants/sharedConstants.js';
import FormContext from '../../formContexts/FormContext.js';
import axios from 'axios';
import urlJoin from 'url-join';
import apiConfig from '../../config/api-config.js';
import { AuthContext } from 'react-oauth2-code-pkce';
import { v4 as uuidv4 } from 'uuid';
import { isRequestOK } from '../../apiCalls/componentsApiCalls.js';
import useFirstRender from '../CustomHooks/useFirstRender.jsx';

const UploadedAttachmentElement = (
    { 
        fileObject, 
        isNotLastLine, 
        category,
        setUploads,
        isNew,
        stepsRef,
        setCurrentApplicant,
        applicantArr,
        setFoundApplicantIndex,
        setAttorneyRequest,
        allUploads,
        tempRadioState,
        prefetchedSvg,
    }
) => {
    const forCsvImport = category.startsWith('csv_import');
    const {
        uid,
        fileName,
        url,
        size,
        message,
        section,
        subSection,
        isDeletable,
        abortController: abortControllerToTrack,
        blobFile,
    } = fileObject;
    const abortSignal = abortControllerToTrack?.signal;
    const { uploadError: uploadErrorsFromContext, setUploadError, setUploadedBytes, setUploadedPrilohyBytes, intention, uploadedBytes, uploadProgressRef, setStagesArr, stepValue } = useContext(FormContext);
    const { token } = useContext(AuthContext);
    const { upload, uploadSuccess, uploadError, uploadCancelled } = useUpload({ forCsvImport, setUploads, setUploadedBytes, setUploadedPrilohyBytes, uploadProgressRef, setUploadError, setStagesArr, stepValue });
    const isUploaded = React.useMemo(() => (!blobFile || uploadSuccess), [blobFile, uploadSuccess]);
    const canDeleteFile = Boolean(isDeletable);
    const errorItem = useMemo(() => [...(uploadErrorsFromContext || [])].find(el => el.uid === uid) ?? null, [uploadErrorsFromContext]);
    const singleUploadError = errorItem?.why === 'single_upload_error';
    const formatError = errorItem?.why === 'format';
    const sizeError = errorItem?.why === 'total_size';
    const individualSizeError = errorItem?.why === 'size';
    const individual0SizeError = errorItem?.why === '0size';
    const checkError = errorItem?.why === 'checkFail';
    const isForm18 = intention === FORM_18_INTENTION.title;
    const isForPOA = category.startsWith('power_of_attorney');
    const isFromModal = (category === PLNA_MOC_SECOND || category === PLNA_MOC_FIFTH);
    const dynamicKey = {
        personProp: (category === PLNA_MOC_THIRD || category === PLNA_MOC_FIFTH) ? 'applicant' : 'attorney',
        fileProp: (category === PLNA_MOC_THIRD || category === PLNA_MOC_FOURTH || category === PLNA_MOC_FIFTH) ? 'powerOfAttorneyRepresentative' : 'powerOfAttorneyFile'
    };
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const handleRetry = () => remapAbortControllerOnError(setUploads, category, uid);
    const firstRender = useFirstRender();

    const jumpBackToZadatele = () => {
        if (category !== WARRANT) {
            return;
        }

        if (!stepsRef || !applicantArr || !setFoundApplicantIndex) {
            return;
        }

        stepsRef?.current[2].click();
        // TODO find a better solution (maybe return a promise)
        setTimeout(() => {
            const foundZadatelIndex = applicantArr.findIndex(el => el.powerOfAttorneyFile.uid === uid);
            const foundZadatelIndexNew = applicantArr.findIndex(el => el.powerOfAttorneyRepresentative.uid === uid);

            if(foundZadatelIndex >= 0) {
                setFoundApplicantIndex(foundZadatelIndex);
            }

            if(foundZadatelIndexNew >= 0) {
                setFoundApplicantIndex(foundZadatelIndexNew);
            }

            if(foundZadatelIndex === -1 && foundZadatelIndexNew === -1) {
                setFoundApplicantIndex(-1);
            }
        }, [500]);
    };
    
    useEffect(() => {
        if (shouldNotFetch(uploadSuccess, uploadCancelled, uploadError, message)) {
            if (!singleUploadError) {
                setUploadError(prev => [...new Map([...prev, {
                    where: section,
                    why: message,
                    uid: uid ?? uuidv4(),
                }].map(el => [el.uid, el])).values()]);              
            }
            return;
        }

        if (isNew) {
            (async() => {
                if (forCsvImport) {
                    upload();
                    return;
                }

                await upload(
                    url,
                    fileObject,
                    abortSignal,
                );
            })();
        }
    }, []);

    useEffect(() => {
        if (uploadSuccess) {
            if (forCsvImport) {
                return;
            }

            if (isForPOA) {
                const poaFile = {
                    uid,
                    fileName,
                    url,
                    section: category,
                    subSection,
                    size,
                    isDeletable,
                };
    
                if (setAttorneyRequest) {
                    setAttorneyRequest(prev => ({...prev, form: 
                        {...prev.form, [dynamicKey.personProp]: 
                            {...prev.form?.[dynamicKey.personProp], 
                                [dynamicKey.fileProp]:  
                                {...poaFile, personId: prev.form?.[dynamicKey.personProp]?.id ?? null}
                            }
                        }}));
                    return;
                }
            }
            return;
        }

        if (uploadCancelled) {
            if (forCsvImport) {
                return;
            }

            if (isForPOA) {
                if (isFromModal && setCurrentApplicant) {
                    setCurrentApplicant(prev => ({...prev, [dynamicKey.fileProp]: POWER_OF_ATTORNEY_FILE_MODEL}));
                    handleRetry();
                    return;
                }

                if (setAttorneyRequest) {
                    setAttorneyRequest(prev => ({...prev, form: 
                        {...prev.form, [dynamicKey.personProp]: 
                            {...prev.form?.[dynamicKey.personProp], 
                                [dynamicKey.fileProp]: POWER_OF_ATTORNEY_FILE_MODEL}
                        }
                    }));
                    handleRetry();
                    return;
                }
            }
            return;
        }

        if (uploadError) {
            if (forCsvImport) {
                return;
            }

            if (isForPOA) {
                if (isFromModal && setCurrentApplicant) {
                    setCurrentApplicant(prev => ({...prev, [dynamicKey.fileProp]: POWER_OF_ATTORNEY_FILE_MODEL}));
                    handleRetry();
                    return;
                }
    
                if (setAttorneyRequest) {
                    setAttorneyRequest(prev => ({...prev, form: 
                        {...prev.form, [dynamicKey.personProp]: 
                            {...prev.form?.[dynamicKey.personProp], 
                                [dynamicKey.fileProp]: POWER_OF_ATTORNEY_FILE_MODEL}
                        }
                    }));
                    handleRetry();
                    return;
                }
            }
            return;
        }

    }, [uploadSuccess, uploadCancelled, uploadError]);

    useEffect(() => {
        if (firstRender) {
            return;
        }
        if (uploadError || singleUploadError) {
            upload(
                url,
                fileObject,
                abortSignal,
            );
            if (singleUploadError) setUploadError(prev => [...prev].filter(el => el.uid !== uid));
        }
    }, [abortSignal]);

    const handleRetryUpload = async () => {
        if (!canReupload(
            errorItem,
            size,
            uploadedBytes,
            setUploadError,
            uid,
            setUploads,
            category,
            allUploads,
        )) {
            return;
        }
        console.log('reupload');
        handleRetry();
    };

    const handleRemove = (id) => {
        handleDeleteUpload(setUploads, category, id);

        if (isTrackableForDokumentace(section)) {
            if (setUploadedBytes) setUploadedBytes(prev => Number(prev) - Number(size));
        } else if (isTrackableForPrilohy(section)) {
            if (setUploadedPrilohyBytes) setUploadedPrilohyBytes(prev => Number(prev) - Number(size));
        }

        if (isForPOA) {
            if (isFromModal && setCurrentApplicant) {
                setCurrentApplicant(prev => ({...prev, [dynamicKey.fileProp]: POWER_OF_ATTORNEY_FILE_MODEL}));
                return;
            }

            if (setAttorneyRequest) {
                setAttorneyRequest(prev => ({...prev, 
                    form: {...prev.form, 
                        [dynamicKey.personProp]: 
                        {...prev.form?.[dynamicKey.personProp], 
                            [dynamicKey.fileProp]: POWER_OF_ATTORNEY_FILE_MODEL}
                    }
                }));
            }
        }
    }; 

    const downloadPdf = async (uid) => {
        try {
            const source = axios.CancelToken.source();

            const response = await axios.get(
                urlJoin(apiConfig.validateAndPersistBaseUrl, `preview-pdf/attachment/${uid}`),
                {
                    responseType: 'blob',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    cancelToken: source.token,
                });

            if (isRequestOK(response?.status)) {
                window.open(URL.createObjectURL(response.data));
            }
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
            } else {
                console.log('Error', error);
            }
        }
    };
    
    return (fileName && 
    <>
        <Row className={`${(message !== undefined || uploadError) ? '' : 'px-3'}`}>
            <Container 
                className={`d-flex align-items-center justify-content-between py-3 ${isNotLastLine ? 'border-bottom' : ''} file-container ${(message !== undefined || uploadError) ? 'error' : ''}`} fluid>
                <Row>
                    <Container
                        className={`d-flex align-items-center justify-content-between file-name ${errorItem ? 'disabled' : ''}`}
                        onClick={() =>{ if(isUploaded && errorItem === null) downloadPdf(uid);}}
                    >
                        <img src={uploadedFile} alt={fileName} />
                        <span className={`ps-2 file-description ${uploadError ? 'error' : ''}`}>
                            {`${fileName} - ${convertBytes(size, 2)}`}
                        </span>
                    </Container>
                </Row>
                <Row>
                    {(message !== undefined) ? 
                        (<Container className={`d-flex align-items-center justify-content-end retry-btn-container ${(formatError || individualSizeError) ? 'disabled' : ''}`} 
                            onClick={() => handleRetryUpload()}>
                            <span className={`${(formatError || individualSizeError || individual0SizeError || checkError) ? 'pe-0' : 'pe-2'} delete-btn-text error`}>{(sizeError || individualSizeError) ? 'Překročena maximální velikost nahrávaných souborů.' : individual0SizeError ? 'Prázdny soubor (velikost 0 bytů), prosím nahrát jiný soubor.' : checkError ? 'Chyba nahrávání / validace souboru' : formatError ? 'Nepodporovaný formát souboru, nelze vložit.' : 'Chyba nahrávání, prosím zkuste znovu.'}</span>
                            <img 
                                className={`${(formatError || individualSizeError || individual0SizeError) ? 'close-error-btn' : ''}`}
                                src={(formatError || individualSizeError || individual0SizeError || checkError) ? prefetchedSvg?.[closeErrorPanelRed] || closeErrorPanelRed : prefetchedSvg?.[reloadIcon] || reloadIcon} 
                                alt={(formatError || individualSizeError || individual0SizeError || checkError) ? 'zavřít' : `nahrát znova ${fileName}`}
                                onClick={() => {
                                    if (formatError || individualSizeError || individual0SizeError || checkError) {
                                        handleDeleteUpload(setUploads, category, uid);
                                        setUploadError(prev => [...prev].filter(el => el.uid !== uid));              
                                    } }}
                            />
                        </Container>)
                        : (category === OTHER_DOCUMENTS && subSection?.length > 0) ? 
                            ((<Container className='d-flex align-items-center delete-btn-container' 
                                onClick={() => downloadPdf(uid)}>
                                <Row>
                                    <button type='button' className='d-flex justify-content-center align-items-center kontrola-button small'>
                                        <img src={file} alt="file" className='kontrola-button-icon' />
                                        Náhled
                                    </button>
                                </Row>
                            </Container>))
                            : (isUploaded)  ?
                                (((canDeleteFile && category !== WARRANT) && 
                                    ((!isForm18 && section !== 'cover_letter') || 
                                    (isForm18 && canDeleteFile))) || (forCsvImport || tempRadioState)
                                    ? 
                                    (<Container className='d-flex align-items-center delete-btn-container' 
                                        onClick={() => {
                                            if (forCsvImport) {
                                                handleRemove(uid);
                                                return;
                                            }
                                            setDeleteModalOpen(true);
                                        }}>
                                        <img src={prefetchedSvg?.[deleteFile] || deleteFile} alt={`smazat ${fileName}`}/>
                                        <span className='ps-2 delete-btn-text'>Smazat</span>
                                    </Container>) 
                                    : (category === WARRANT) ? 
                                        (<Container className='d-flex align-items-center delete-btn-container' 
                                            onClick={() => jumpBackToZadatele()}>
                                            <img src={iconChange} alt={'Upravit žadatele'}/>
                                            <span className='ps-2 delete-btn-text'>Upravit žadatele</span>
                                        </Container>) 
                                        : '') 
                                : (uploadError) ? 
                                    (<Container className='d-flex align-items-center justify-content-end retry-btn-container' 
                                        onClick={() => handleRetry()}>
                                        <span className='pe-2 delete-btn-text error'>Chyba nahrávání, prosím zkuste znovu.</span>
                                        <img src={prefetchedSvg?.[reloadIcon] || reloadIcon} alt={`nahrát znova ${fileName}`}/>
                                    </Container>) 
                                    : (!uploadSuccess) ? 
                                        (<Container className='d-flex align-items-center cancel-btn-container' 
                                            onClick={() => {
                                                cancelUpload(abortControllerToTrack);
                                            }}>
                                            <span className='ps-2 delete-btn-text'>Zrušit</span>
                                        </Container>) 
                                        : ''}
                </Row>
            </Container>
            {(!uploadError && !message) && <Progress uid={uid}/>}
        </Row>
        {deleteModalOpen && 
        <ConfirmBeforeDeleteModal
            deleteModalOpen={deleteModalOpen}
            setDeleteModalOpen={setDeleteModalOpen}
            confirmOperation={handleRemove}
            itemToDeleteId={uid}
            itemToDeleteType={'soubor'}
        />}
    </>
    );
};

UploadedAttachmentElement.propTypes = {
    fileObject: PropTypes.shape({
        uid: PropTypes.string,
        blobFile: PropTypes.object,
        fileName: PropTypes.string, 
        url: PropTypes.string,
        size: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]),
        message: PropTypes.string,
        section: PropTypes.string,
        subSection: PropTypes.string,
        isDeletable: PropTypes.bool,
        abortController: PropTypes.any,
    }),
    isNotLastLine: PropTypes.bool,
    category: PropTypes.string,
    setUploads: PropTypes.func,
    isNew: PropTypes.bool,
    stepsRef: PropTypes.objectOf(Array),
    applicantArr: PropTypes.arrayOf(Object),
    setCurrentApplicant: PropTypes.func,
    setFoundApplicantIndex: PropTypes.func,
    setAttorneyRequest: PropTypes.func,
    allDocuments: PropTypes.object,
    urlPath: PropTypes.string,
    tempRadioState: PropTypes.bool
};

export default UploadedAttachmentElement;
