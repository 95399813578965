import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import '../../css/zadosti.css';
import { trashbin, house } from '../../assets';
import { Link } from 'react-router-dom';
import moment from 'moment';
import cs from 'moment/locale/cs';
import { PATHS } from '../../constants/sharedConstants';
import ConfirmBeforeDeleteModal from '../Forms/ZamerComponentTables/ConfirmBeforeDeleteModal';

moment.locale('cs', cs);

export const isRequestSentOrError = (item) => {
    return item?.sendStatus?.toUpperCase() === 'SENDING' || item?.sendStatus?.toUpperCase() === 'SENDING_ERROR';
};

const ZadostiBlockDisplayDeveloped = ({ item, handleDelete }) => {
    const location = useLocation();
    const [ requestDetails, setRequestDetails ] = useState({
        requestUrl: '',
        requestDate: '',
    });
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    

    const handleTitleAndDate = (title, date) => {
        setRequestDetails(prev => ({...prev, 
            requestUrl: [...PATHS].find(el => el.title === title)?.target, 
            // requestDate: moment(date, 'YYYY-MM-DD hh:mm:ss').fromNow()
            requestDate: moment(date).format('LLL')
        }));
    };

    useEffect(() => {
        if (!item) {
            return;
        }

        handleTitleAndDate(item.title, item.updated);
    }, []);    

    return (
        <>
            <Container className='d-flex flex-column border rounded p-3 py-4 zadosti-container' style={{backgroundColor: item?.sendStatus?.toUpperCase() === 'SENDING' ? 'transparent' : '#FFFFFF'}} fluid>
                <Row className='d-flex align-items-center'>
                    <p className='p-0 mb-3 zadosti-title'>{item.title}</p>
                </Row>
    
                <Row className='mb-4'>
                    <Col>
                        <Row className='d-flex align-items-center mb-3'>
                            <p className='p-0 zadosti-id'>Dočasný identifikátor: </p>
                            <p className='p-0 zadosti-id'>{item.id}</p>
                        </Row>
                        {/* <Row className='d-flex align-items-center py-1 border-bottom'>
                                <Col sm={4}>
                                    <p className='zadosti-row-name'>Číslo záměru</p>
                                </Col>
                                <Col sm={8}>
                                    <p className='zadosti-row-info'>{item.intentionNumber ? item.intentionNumber : 'Rozpracovaná žádost'}</p>
                                </Col>
                            </Row> */}
                        <Row className='d-flex align-items-center py-1 border-bottom'>
                            <Col sm={4}>
                                <p className='zadosti-row-name'>Záměr</p>
                            </Col>
                            <Col sm={8}>
                                <p className='zadosti-row-info text-truncate'>{item.intentionName}</p>
                            </Col>
                        </Row>
                        <Row className='d-flex align-items-center py-1'>
                            <Col sm={4}>
                                <p className='zadosti-row-name'>Upraveno</p>
                            </Col>
                            <Col sm={8}>
                                {/* <p className='zadosti-row-info'>{requestDetails.requestDate === 'Invalid date' ? 'Neuvedeno' : requestDetails.requestDate}</p> */}
                                <p className='zadosti-row-info'>{requestDetails.requestDate}</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
    
                <Row className='mt-auto justify-self-end'>
                    <Col>
                        {isRequestSentOrError(item) ?
                            <Row>
                                <p className='d-flex justify-content-center p-0'>
                                    <img src={house} alt='house' className='pe-2' />Předáno ke zpracování
                                </p>
                            </Row>
                            :
                            <Row className='d-flex flex-nowrap justify-content-between'>
                                <button type='button' onClick={() => setDeleteModalOpen(true)}
                                    className='stavby-button-dev stavby-button-dev--delete'>
                                    <img src={trashbin} alt='trashbin' className='pe-2' />Smazat
                                </button>
                                <Link to={`/zadost/${requestDetails.requestUrl}/${encodeURIComponent(item?.id)}`} state={{from: location}} className='p-0 stavby-button-link'><button type='button' className='stavby-button-dev'>Pokračovat</button></Link>
                            </Row>}
                    </Col>
                </Row>
            </Container>
            {deleteModalOpen && 
            <ConfirmBeforeDeleteModal
                deleteModalOpen={deleteModalOpen}
                setDeleteModalOpen={setDeleteModalOpen}
                confirmOperation={handleDelete}
                itemToDeleteId={item?.id}
                itemToDeleteType={'zadost'}
            />}
        </>
    );
};

ZadostiBlockDisplayDeveloped.propTypes = {
    item: PropTypes.shape({
        title: PropTypes.string,
        id: PropTypes.string,
        intentionNumber: PropTypes.string,
        intentionName: PropTypes.string,
        updated: PropTypes.string,
        sendStatus: PropTypes.string,
    }),
    handleDelete: PropTypes.func
};

export default ZadostiBlockDisplayDeveloped;