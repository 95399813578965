import React, { useState, useContext, useEffect, useRef, useImperativeHandle } from 'react';
import { AuthContext } from 'react-oauth2-code-pkce';
import axios from 'axios';
import { Container, Row } from 'react-bootstrap';
import '../../css/zadostPovoleniStavby.css';
import '../../css/zamerComponent.css';
import PropTypes from 'prop-types';
import { formSave, getFormById } from '../../apiCalls/formApiCalls';
import FormContext from '../../formContexts/FormContext.js';
import { shouldNotSave,  spreadObject, hasZamerError, setUploadedDocumentsSizeFromZamer, getOngoingUploads, isPruvodniListNotYetSubmitted } from '../../helperFunctions/helpers.js';
import usePrevious from '../CustomHooks/usePrevious.jsx';
import ZamerSelectionControls from '../ZamerSelectionControls.jsx';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { fetchZamerDetails, isRequestOK } from '../../apiCalls/componentsApiCalls.js';
import { DEFAULT_DOCUMENTATION_FORMAT } from '../../constants/sharedConstants.js';

const buildIntentionModel = {
    title: { value: null, dirty: false },
};

const DocumentationZamer = ({ urlPath, documents, setDocuments }) => {
    const {intention, stepValue, validationRef, setStagesArr, saveFormRef, id, setUploadedBytes, setWaitingForPruvodniList} = useContext(FormContext); 
    const ongoingUploads = React.useMemo(() => getOngoingUploads(Object.values(documents || {})?.flat()), [documents]);
    const [request, setRequest] = useState({
        'applicationId': id,
        buildIntention: buildIntentionModel
    });
    const [isIntentionCheckboxDisabled, setIsIntentionCheckboxDisabled] = useState(false);
    const prevRequest = usePrevious(request);
    const { token } = useContext(AuthContext);
    const initialRender = useRef(true);
    const [state, setState] = useState({
        inputValue: '',
        zamerType: 'new',
    });
    const [loading, setLoading] = useState(false);
    const { versionId } = useParams();
    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const source = axios.CancelToken.source();
        if (id) {
            (async () => {
                if (!versionId) {
                    try {
                        const response = await getFormById(id, token, source, intention, null, pathname, navigate);
                        if (isRequestOK(response?.status)) {
                            const isCopied = response.data?.buildApplication?.isCopied || false;
                            setIsIntentionCheckboxDisabled(isCopied);
                            // do not send id: null if a form is present
                            const formData = response.data?.buildApplicationForms?.[0];
                            const intention = response.data?.buildIntention;
                            if (intention) {
                                // eslint-disable-next-line no-unused-vars
                                const { buildConstructions, buildParcels, affectedBuildConstructions, affectedBuildParcels, approvedConstructions, ...rest } = intention;
                                setRequest(state => ({
                                    ...state,
                                    buildIntention: {...spreadObject(rest), ...DEFAULT_DOCUMENTATION_FORMAT},
                                    ...((formData && formData.id) ? { form: { id: formData.id } } : {}) 
                                }));
                                setState(prevSate => ({ 
                                    ...prevSate,
                                    inputValue: intention.title.value, 
                                    zamerType: intention.eszId ? 'existing' : 'new' 
                                }));
                            }
                            const loadedBuildApplication = response?.data?.buildApplication || {};
                            const documentations = loadedBuildApplication?.documentations ?? null;
                            if (documentations && !ongoingUploads?.length) setUploadedDocumentsSizeFromZamer(documentations, setUploadedBytes);
                            if (setWaitingForPruvodniList) setWaitingForPruvodniList(isPruvodniListNotYetSubmitted(response?.data ?? {}));
                        } else {
                            console.log('failed getFormById');
                        }
                    } catch (error) {
                        console.log(error);
                    } finally {
                        setLoading(false);
                    }
                } else {
                    try {
                        const withVersionId = await fetchZamerDetails(versionId, token, undefined, null);
            
                        if (isRequestOK(withVersionId?.status)) {
                            const withVersionIdData = withVersionId?.data?.data;
                            const withVersionIdDataBuildInt = withVersionIdData?.buildIntention ?? {};
            
                            const withoutVersionId = await getFormById(id, token, source, intention, null, pathname, navigate);
            
                            if (isRequestOK(withoutVersionId?.status)) {
                                const withoutVersionIdData = withoutVersionId?.data;
                                const withoutVersionIdDataBuildInt = withoutVersionIdData?.buildIntention ?? {};
            
                                // OVERRIDE ONLY INSERTED VALUES
                                const combinedBuildIntentionData = {...spreadObject(withVersionIdDataBuildInt), ...spreadObject(withoutVersionIdDataBuildInt), ...DEFAULT_DOCUMENTATION_FORMAT};
        
                                if (combinedBuildIntentionData) {
                                    setRequest(prev => ({
                                        ...prev,
                                        buildIntention: {
                                            ...prev?.buildIntention,
                                            ...combinedBuildIntentionData,
                                            title: { 
                                                ...prev?.buildIntention?.title, 
                                                value: withVersionIdDataBuildInt?.title?.value ?? '' // force object to take versionId title
                                            }
                                        },
                                    }));
                                    setIsIntentionCheckboxDisabled(Boolean(withVersionIdDataBuildInt?.title?.value));
                                    setState(prev => ({ 
                                        ...prev,
                                        inputValue: withVersionIdDataBuildInt?.title?.value ?? '', 
                                        zamerType: 'existing' // default to existing in new version
                                    })); 
                                }
                            } else {
                                console.log('getFormById failed');
                            }
                        } else {
                            console.log('fetchZamerDetails failed');
                        }
                    } catch (error) {
                        console.log(error);
                    } finally {
                        setLoading(false);
                    }
                }
            })();

            return () => {
                source.cancel('Operation canceled by the user.');
            };
        }
        else {
            console.log('NON EXISTENT ID');
            setLoading(false);
        }
    }, []);

    const saveChanges = async () => {
        const source = axios.CancelToken.source();
        await formSave(request, token, urlPath, source);
    };

    const buildIntention = request?.buildIntention;

    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
        } else {
            // avoid multiple save for same object
            if (shouldNotSave(prevRequest.buildIntention, buildIntention)) {
                return;
            }

            if (buildIntention?.title?.value === null) {
                return;
            }

            saveChanges();
        }
    }, [buildIntention]);

    useImperativeHandle(saveFormRef, () => ({
        saveForm: () => saveChanges()
    }));

    useImperativeHandle(validationRef, () => ({
        validate() {
            let err = false;

            if (hasZamerError(buildIntention)) {
                err = true;
            }

            setStagesArr(prev => [...prev].map(stg => {
                if (Number(stg.stage) === Number(stepValue)) {
                    
                    return {...stg, error: err ?? false, };
                }
                return stg;
            }));

            return !err;
        }
    }));

    return (
        <>
            {loading ? <div className='loading'></div> :
                <Container fluid>
                    <Row className='row-wrapper'>
                        <h2 className='p-0 m-0 main-title'>{`${stepValue}. Záměr`}</h2>
                    </Row>

                    <ZamerSelectionControls
                        title={'Název nového záměru'}
                        request={request} 
                        setRequest={setRequest} 
                        state={state} 
                        setState={setState} 
                        buildIntentionModel={buildIntentionModel} 
                        isIntentionCheckboxDisabled={isIntentionCheckboxDisabled}
                        documents={documents}
                        setDocuments={setDocuments}
                    />

                </Container>}
        </>
    );
};

DocumentationZamer.propTypes = {

    urlPath: PropTypes.string.isRequired,
};

export default DocumentationZamer;