import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import PropTypes from 'prop-types';
import '../../css/uploads.css';
import { UploadedAttachmentElement } from '../index.js';
import {
    PLNA_MOC_FIFTH,
    PLNA_MOC_FIRST,
    PLNA_MOC_FOURTH,
    PLNA_MOC_SECOND,
    PLNA_MOC_THIRD
} from '../../constants/sharedConstants.js';
import { canInitiateUpload } from '../../helperFunctions/helpers.js';

const UploadedAttachments = ({ 
    stepsRef, 
    category, 
    attachments, 
    setUploads, 
    currentApplicant,
    setCurrentApplicant,
    setFoundApplicantIndex,
    applicantArr,
    setAttorneyRequest,
    allUploads,
    urlPath,
    tempRadioState,
    prefetchedSvg,
}) => {
    const [oldAttachments, setOldAttachments] = useState([...attachments]);
    const newAttachments = [...attachments].filter(el => !([...oldAttachments].some(element => element.uid === el.uid)));

    useEffect(() => {
        if (attachments.length === 0) {
            return;
        }

        const oldDocs = [...attachments].filter(el => [...oldAttachments].find(element => element.uid === el.uid));
        setOldAttachments([...oldDocs]);
        
    }, [attachments.length]);

    return (
        <Container className={`${attachments.length === 0 ? 'd-none' : ''} pt-3`} fluid>
            <Container 
                className={`bg-white ${((category !== PLNA_MOC_SECOND && category !== PLNA_MOC_FIFTH) && attachments.length > 0) || 
                ((category === PLNA_MOC_SECOND || category === PLNA_MOC_FIFTH) && 
                currentApplicant?.powerOfAttorneyFile instanceof Object && 
                currentApplicant?.powerOfAttorneyFile?.fileName) ? 'border' : ''}`} fluid
            >
                {attachments.length > 0 && attachments.map((fileObj, index, array) => { 
                    if (category.startsWith('power_of_attorney') &&
                        (((category === PLNA_MOC_SECOND || category === PLNA_MOC_FIFTH) && currentApplicant?.id === fileObj?.personId) || 
                        (category === PLNA_MOC_FIRST || category === PLNA_MOC_THIRD || category === PLNA_MOC_FOURTH))
                    ) {
                        return (<UploadedAttachmentElement
                            key={fileObj.uid || index}
                            fileObject={fileObj}
                            isNotLastLine={index !== array.length -1}
                            category={category}
                            setUploads={setUploads}
                            isNew={canInitiateUpload(newAttachments, fileObj)}
                            setCurrentApplicant={setCurrentApplicant}
                            setAttorneyRequest={setAttorneyRequest}
                        />);
                    } else if (!category.startsWith('power_of_attorney')) {
                        return (<UploadedAttachmentElement
                            key={fileObj.uid || index}
                            fileObject={fileObj}
                            isNotLastLine={index !== array.length -1}
                            category={category}
                            setUploads={setUploads}
                            isNew={canInitiateUpload(newAttachments, fileObj)}
                            stepsRef={stepsRef}
                            setFoundApplicantIndex={setFoundApplicantIndex}
                            applicantArr={applicantArr}
                            allUploads={allUploads}
                            urlPath={urlPath}
                            tempRadioState={tempRadioState}
                            prefetchedSvg={prefetchedSvg}
                        />);
                    }
                })}
            </Container>
        </Container>
    );
};

UploadedAttachments.propTypes = {
    category: PropTypes.string,
    currentApplicant: PropTypes.objectOf(String),
    setCurrentApplicant: PropTypes.func,
    attachments: PropTypes.arrayOf(Object),
    setUploads: PropTypes.func,

    setFoundApplicantIndex: PropTypes.func,
    setAttorneyRequest: PropTypes.func,
    stepsRef: PropTypes.objectOf(Array),
    applicantArr: PropTypes.arrayOf(Object),
    receivedToken: PropTypes.string,
    urlPath: PropTypes.string,
    allUploads: PropTypes.object,
    tempRadioState: PropTypes.bool
};

export default UploadedAttachments;

