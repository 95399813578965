import { matchPath, removeOAuthLocalStorageWithReload } from '../utils.js';
import { STORAGE_PREFIX_KEY, TOKEN_EXPIRE_LOCALSTORAGE_NAME } from '../constants.js';

const useRemoveExpiredOfficerToken = () => {
    const isOfficerRoute = matchPath('/urednik', window.location.pathname);

    if (isOfficerRoute) {
        const localStorageTokenExpiration = localStorage.getItem(`${STORAGE_PREFIX_KEY}${TOKEN_EXPIRE_LOCALSTORAGE_NAME}`);

        if (!localStorageTokenExpiration || localStorageTokenExpiration === '""') {
            return null;
        }

        const isExpired = Math.floor(new Date().getTime() / 1000) > localStorageTokenExpiration;

        if (isExpired) {
            removeOAuthLocalStorageWithReload();
        }
    }

    return null;
};

export default useRemoveExpiredOfficerToken;