export const TOKEN_PARAM_NAME = 'token';
export const AUTH_PARAM_NAME = 'auth';
export const ERROR_CODE_PARAM_NAME = 'errorCode';
export const STORAGE_PREFIX_KEY = 'ROCP_';

export const ID_TOKEN_LOCALSTORAGE_NAME = 'idToken';
export const LOGIN_IN_PROGRESS_LOCALSTORAGE_NAME = 'loginInProgress';
export const REFRESH_TOKEN_EXPIRE_LOCALSTORAGE_NAME = 'refreshTokenExpire';
export const TOKEN_LOCALSTORAGE_NAME = 'token';
export const TOKEN_EXPIRE_LOCALSTORAGE_NAME = 'tokenExpire';
export const PRE_LOGIN_PATH_LOCALSTORAGE_NAME = 'preLoginPath';

export const PKCE_CODE_VERIFIER = 'PKCE_code_verifier';