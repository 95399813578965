import React, { useState, useContext, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { DocumentationZamer, FormProgress, DocumentationZadatel, Dokumentace } from '../index.js';
import { AuthContext } from 'react-oauth2-code-pkce';
import { formSave } from '../../apiCalls/formApiCalls.js';
import { NEW_DOCUMENTATION } from '../../constants/sharedConstants.js';
import FormContext from '../../formContexts/FormContext.js';
import { isRequestOK } from '../../apiCalls/componentsApiCalls.js';
import axios from 'axios';
import { getUploadedPruvodniList, isDokumentace, sendDocumentationRequest } from '../../helperFunctions/helpers.js';

const DocumentationMainSection = ({
    stage,
    isMobileView,
    documents,
    setDocuments,
}) => {
    const [loading, setLoading] = useState(true);
    const { token } = useContext(AuthContext);
    let { id: urlId } = useParams();
    const urlPath = 'quick-save-api/documentation/save';
    const stepsRef = useRef([]);
    const [foundApplicantIndex, setFoundApplicantIndex] = useState(null);
    const [applicantArr, setApplicantArr] = useState([]);
    const {setId, stepValue, stagesArr, id, setWaitingForPruvodniList} = useContext(FormContext);
    const documentsToSaveManually = React.useMemo(() => 
        [...(Object.values(documents || {})?.flat() || [])].filter(fileObj => (!fileObj.blobFile && !fileObj.abortController && !fileObj.message)), 
    [documents]);

    useEffect(() => {
        if (!urlId) {
            (async () => {
                let data = JSON.stringify({
                    'buildApplication': {
                        'title': NEW_DOCUMENTATION.get((false))?.title
                    }
                });

                const response = await formSave(data, token, urlPath);
                if (isRequestOK(response?.status)) {
                    setId(response.data);
                    setLoading(false);
                }
            })();
        }

        else {
            setId(urlId);
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (!documentsToSaveManually.length || isDokumentace(stepValue, stagesArr)) {
            return; 
        }
        const source = axios.CancelToken.source();
        const requestObject = sendDocumentationRequest(id, [...(Object.values(documents || {}) || []).flat().filter(el => !el.message), ...documentsToSaveManually]);
        if (requestObject) {
            formSave(requestObject, token, urlPath, source);
        }
        if (setWaitingForPruvodniList) setWaitingForPruvodniList(!getUploadedPruvodniList(documentsToSaveManually));
    }, [documentsToSaveManually.length]);

    return (
        <Container fluid className='d-flex justify-content-center px-3 pt-0 pb-3 p-lg-5'>
            <Row className='base-width'>
                <Col className='d-flex justify-content-center justify-content-md-end d-md-block d-lg-none mb-4 mb-lg-0'>
                    {isMobileView && 
                        <FormProgress
                            stepsRef={stepsRef}
                            isMobile={isMobileView}
                        />
                    }
                </Col>
                <Col xs={{ span: 12 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 8 }} className='pe-lg-3'>
                    {loading ? (<span>Načítám formulář...</span>) :
                        (<>
                            {stage === 1 && <DocumentationZamer
                                urlPath={urlPath}
                                documents={documents}
                                setDocuments={setDocuments}
                            />}
                            {stage === 2 && <DocumentationZadatel
                                urlPath={urlPath}
                                stage={stage}
                                foundApplicantIndex={foundApplicantIndex}
                                applicantArr={applicantArr}
                                setApplicantArr={setApplicantArr}
                                setFoundApplicantIndex={setFoundApplicantIndex}
                            />}
                            {stage === 3 && <Dokumentace
                                urlPath={urlPath}
                                documents={documents}
                                setDocuments={setDocuments}
                            />}
                        </>)}
                </Col>
                <Col lg={{ span: 4 }} xl={{ span: 4 }} className='d-flex justify-content-center justify-content-md-end d-none d-lg-block mb-md-5'>
                    {!isMobileView && 
                        <FormProgress
                            stepsRef={stepsRef}
                            isMobile={isMobileView}
                        />
                    }
                </Col>
            </Row>
        </Container>
    );
};

export default DocumentationMainSection;

DocumentationMainSection.propTypes = {
    stage: PropTypes.number.isRequired,
    progressIndicators: PropTypes.arrayOf(Number),
    setProgressIndicators: PropTypes.func,
    isMobileView: PropTypes.bool,
    isNewVersion: PropTypes.bool,
    documents: PropTypes.object,
    setDocuments: PropTypes.func,
};
