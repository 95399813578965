import { ErrorBoundary } from 'react-error-boundary';
import React, { useEffect, useState } from 'react';
import '../css/reusable.css';
import { Container } from 'react-bootstrap';
import { FormFooter, FormHeader, Form10MainSection, FormFinisher, ProcessError } from '../components';
import { useGlobalState } from '../constants/states';
import { isNotUploading, logError } from '../helperFunctions/helpers';
import { items } from '../constants/form10';
import { AFFIDAVIT, FORM_10_INTENTION, OTHER_DOCUMENTS, OVERALL_SITUATION, OWNER_CONSENT, PARTICIPANTS_LIST, WARRANT } from '../constants/sharedConstants';
import FormContext from '../formContexts/FormContext';
import useScrollToTop from '../components/CustomHooks/useScrollToTop';

const Form10 = () => {
    const [state, setState] = useGlobalState();
    
    const [ isMobileView, setIsMobileView ] = useState(window.innerWidth < 992);
    const [isFinished, setIsFinished] = useState(false);
    const [id, setId] = useState(null);
    const [uploadError, setUploadError] = useState([]);
    const [isSuccessOrFailSubmission, setIsSuccessOrFailSubmission] = useState(null);
    const [uploadedBytes, setUploadedBytes] = useState(0);
    const [uploadedPrilohyBytes, setUploadedPrilohyBytes] = useState(0);
    const [stagesArr, setStagesArr] = useState(items);
    const [isDetailsMatching, setIsDetailsMatching] = useState(null);
    const [ fetch, setFetch ] = useState(false);
    const { stage } = state;
    useScrollToTop(stage);
    const uploadProgressRef = React.useRef({});
    const [attachments, setAttachments] = useState({
        [WARRANT]: [],
        [AFFIDAVIT]: [],
        [OWNER_CONSENT]: [],
        [PARTICIPANTS_LIST]: [],
        [OVERALL_SITUATION]: [],
        [OTHER_DOCUMENTS]: [],
    });
    const allUploads = React.useMemo(() => [...[...(Object.values(attachments || {}) || [])].flat()], [attachments]);

    useEffect(() => {
        function handleBeforeUnload (e) {
            if (!isNotUploading(allUploads)) {
                e.preventDefault();
            }
        }

        function handleResize() {
            if(window.innerWidth < 992) {
                setIsMobileView(true);
                return;
            } 

            setIsMobileView(false); 
        }

        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('resize', handleResize);
        };
    }, [allUploads]);

    const setStage = (newStage) => {
        setState({ ...state, stage: newStage });
    };

    useEffect(() => {
        return () => {
            setStage(1);
        };
    }, []);

    const validationFunctionRef = React.useRef();
    const saveFormRef = React.useRef();

    return (
        <ErrorBoundary fallback={<ProcessError idFromBoundary={id} isFromErrorBoundary />} onError={logError}>
            <Container className={`d-flex flex-column align-items-center pt-5 background ${isMobileView ? 'mobile' : ''}`} fluid>
                {isFinished ? <FormFinisher isSuccessOrFailSubmission={isSuccessOrFailSubmission} header={FORM_10_INTENTION.title} id={id} linkRef={'form10'}
                    setStage={setStage} setIsFinished={setIsFinished} /> :
                    <>
                        <FormContext.Provider
                            value={{
                                intention: FORM_10_INTENTION.title,
                                stepValue: stage,
                                uploadError,
                                setUploadError,
                                uploadedBytes,
                                setUploadedBytes,
                                uploadedPrilohyBytes,
                                setUploadedPrilohyBytes,
                                uploadProgressRef,
                                validationRef: validationFunctionRef,
                                setStagesArr,
                                stagesArr,
                                saveFormRef,
                                id,
                                setId,
                                fetch,
                                setFetch,
                                isDetailsMatching,
                                setIsDetailsMatching
                            }}
                        >
                            <FormHeader isMobileView={isMobileView} header={FORM_10_INTENTION} />
                            <Form10MainSection attachments={attachments} setAttachments={setAttachments} isMobileView={isMobileView} stage={stage}   />
                            <FormFooter setIsSuccessOrFailSubmission={setIsSuccessOrFailSubmission} formNumber={'10'} setIsFinished={setIsFinished} />
                        </FormContext.Provider>
                    </>}
            </Container>
        </ErrorBoundary>
    );
};

export default Form10;