import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Dokumentace, ZamerControlsMinimal } from '../index.js';
import { AuthContext } from 'react-oauth2-code-pkce';
import FormContext from '../../formContexts/FormContext.js';
import { fetchExistingDocumentation, isRequestOK } from '../../apiCalls/componentsApiCalls.js';
import useFirstRender from '../CustomHooks/useFirstRender.jsx';
import usePrevious from '../CustomHooks/usePrevious.jsx';
import axios from 'axios';
import { getFormById } from '../../apiCalls/formApiCalls.js';
import { useLocation, useNavigate } from 'react-router-dom';
import { getOngoingUploads, handleSpreadUploadedDocuments, spreadObject } from '../../helperFunctions/helpers.js';

const NovaDokumentaceMainSection = ({
    documents,
    setDocuments,
    urlPath,
    isPlEmptyForUrednik = false,
    isDocumentationWithId = false,
}) => {
    const buildIntentionModel = {
        title: { 
            value: null, 
            dirty: false 
        },
        accompanyingDocumentPdf: null,
        accompanyingDocumentDeveloper: null,
    };
    const { token } = useContext(AuthContext);
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { id, setUploadedBytes, intention } = useContext(FormContext);
    const [request, setRequest] = useState({
        'applicationId': id,
        buildIntention: buildIntentionModel,
        'buildApplication': {
            'documentations': [],
            shareForm: {
                firstName: '',
                lastName: '',
                paperType: 'ID',
                paperNumber: ''
            },
            authorizedPersonSharedLink: ''
        },
        'accompanyingDocument': {
            accompanyingDocumentStatus: '' 
        },
    });
    const firstRender = useFirstRender();
    const bppPid = request?.buildIntention?.bppPid ?? null;
    const previousBppPid = usePrevious(request?.buildIntention?.bppPid) ?? null;
    const [state, setState] = useState({
        inputValue: '',
        zamerType: 'new',
    });
    const onGoingUploads = useMemo(() => getOngoingUploads(Object.values(documents || {})?.flat()), [documents]);
    const [fetching, setFetching] = useState(true);

    useEffect(() => {
        if (id) {
            const source = axios.CancelToken.source();
            (async () => {
                const response = await getFormById(id, token, source, intention, null, pathname, navigate);
                if (isRequestOK(request?.status)) {
                    const loadedBuildIntention = response?.data?.buildIntention || {};
                    const loadedBuildApplication = response?.data?.buildApplication || {};
                    const loadedAccompanyingDocument = response?.data?.accompanyingDocument || {};

                    setRequest(prev => ({
                        ...prev,
                        buildIntention: {...prev?.buildIntention,
                            ...spreadObject(loadedBuildIntention),
                            accompanyingDocumentPdf: loadedBuildIntention?.accompanyingDocumentPdf ?? true,
                            accompanyingDocumentDeveloper: loadedBuildIntention?.accompanyingDocumentDeveloper ?? false,
                        },
                        buildApplication: {...prev?.buildApplication, 
                            authorizedPersonSharedLink: loadedBuildApplication?.authorizedPersonSharedLink ?? '', 
                            shareForm: {
                                ...prev?.buildApplication?.shareForm, 
                                ...loadedBuildApplication?.shareForm
                            }
                        },
                        accompanyingDocument: {...prev?.accompanyingDocument,
                            ...spreadObject(loadedAccompanyingDocument)
                        }
                    }));

                    setState(prevSate => ({ 
                        ...prevSate,
                        inputValue: (loadedBuildIntention.icsId || loadedBuildIntention?.title?.value) ? (loadedBuildIntention.icsId ?? '') + ((loadedBuildIntention.icsId && loadedBuildIntention?.title?.value) ? ' - ' : '') + (loadedBuildIntention?.title?.value ?? '') : '',
                        zamerType: (loadedBuildIntention.eszId ) ? 'existing' : 'new' 
                    }));

                    const documents = loadedBuildApplication?.documentations;
                    const defaultUploadHere = { documentUploadHere: true };
                    handleSpreadUploadedDocuments([...(documents || []), ...onGoingUploads], defaultUploadHere, setDocuments, setUploadedBytes);
                }
                setFetching(false);
            })();

            return () => {
                source.cancel('Operation canceled by the user.');
            };
        }
        else {
            console.log('NON EXISTENT ID');
            setFetching(false);
        }
    }, []);

    useEffect(() => {
        if (firstRender || !request.buildIntention.id) {
            return;
        }

        if (previousBppPid && (!bppPid || (bppPid && (previousBppPid !== bppPid)))) {
            const resetObject = {};
            for (let key in documents) {
                if (Object.hasOwn(documents, String(key))) {
                    resetObject[key] = [];
                }
            }
            setUploadedBytes(0);
            setDocuments(resetObject);
        }

        fetchExistingDocumentation(request.buildIntention.bppPid, token, setDocuments, setUploadedBytes);
    }, [request.buildIntention.bppPid]);

    return (
        <>
            {!fetching &&
            <>
                <ZamerControlsMinimal 
                    urlPath={urlPath}
                    title={'Číslo a název záměru'}
                    isDocumentationWithId={isDocumentationWithId}
                    request={request}
                    setRequest={setRequest}
                    state={state}
                    setState={setState}
                    buildIntentionModel={buildIntentionModel}
                />
                <Dokumentace
                    isPlEmptyForUrednik={isPlEmptyForUrednik}
                    urlPath={urlPath}
                    documents={documents}
                    setDocuments={setDocuments}
                    documentationFormRequest={request}
                />
            </>}
        </>
    );
};

NovaDokumentaceMainSection.propTypes = {

    documents: PropTypes.object,
    setDocuments: PropTypes.func,
    isDocumentationWithId: PropTypes.any,
    urlPath: PropTypes.any,
};

export default NovaDokumentaceMainSection;