import { useState, useEffect } from 'react';

/**
 * @param {string[]} svgs - list of svg paths, can be used for other images too
 * @returns {object} svgDataUris
 */
const usePrefetchSVGs = (svgs) => {
    const [svgDataUris, setSvgDataUris] = useState({});

    useEffect(() => {
        const preloadSVGs = async () => {
            if (!Array.isArray(svgs)) {
                console.log('type error');
                return;
            }

            const dataUriMap = {};
            const cache = new Set();
            for (const svg of svgs) {
                if (!cache.has(svg)) {
                    try {
                        const response = await fetch(svg, { method: 'GET', cache: 'force-cache' });
                        if (response?.ok) {
                            const svgText = await response?.text();
                            const base64 = btoa(svgText);
                            dataUriMap[svg] = `data:image/svg+xml;base64,${base64}`;
                            cache?.add(svg);
                        } else {
                            console.error('Failed to fetch', svg);
                        }
                    } catch (error) {
                        console.error('Error fetching svg', error);
                    }
                } else {
                    console.log('already cached');
                }
            }
            setSvgDataUris((prev) => ({ ...prev, ...dataUriMap }));
        };

        preloadSVGs();
    }, []);

    return svgDataUris;
};

export default usePrefetchSVGs;