
import {Col, Container, Row} from 'react-bootstrap';
import {closeErrorPanel} from '../../assets/index.js';
import React from 'react';
import { NEW_DOCUMENTATION } from '../../constants/sharedConstants.js';
import FormHeader from '../Forms/FormHeader.jsx';
import FormContext from '../../formContexts/FormContext.js';
import PropTypes from 'prop-types';


const UrednikCancel = ({isOfficer}) => {
    const [uploadError, setUploadError] = React.useState([]);

    return (
        <Container className='d-flex flex-column justify-content-center align-items-center' fluid>
            <FormContext.Provider
                value={{
                    uploadError,
                    setUploadError,
                    isOfficer
                }}
            >
                <FormHeader
                    header={NEW_DOCUMENTATION.get((true))}
                />
            </FormContext.Provider>
            <Row className='base-width mb-5 d-flex flex-column justify-content-center align-items-center text-center'>

                <Row className='mb-3'>
                    <Col><img src={closeErrorPanel} style={{ width: '80px', height: '80px', filter: 'brightness(0) saturate(100%) invert(25%) sepia(42%) saturate(3949%) hue-rotate(334deg) brightness(85%) contrast(94%)' }}/></Col>

                </Row>
                <Row className='mb-3'>
                    <p style={{ fontSize: '23px'}}>Vložení dokumentace</p>
                </Row>
                <Row className='mb-3'>
                    <p className='justify-content-center align-items-center' style={{ 'fontSize': '32px' }}>Zrušeno</p>
                </Row>
                <Row className='mb-3'>
                    <p>Můžete zavřít tuto záložku prohlížeče.</p>
                </Row>
            </Row>
        </Container>
    );
};

UrednikCancel.propTypes = {
    isOfficer: PropTypes.bool
};

export default UrednikCancel;
