/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import { Col, Modal, Row } from 'react-bootstrap';

const ConfirmBeforeDeleteModal = ({
    deleteModalOpen,
    setDeleteModalOpen,
    confirmOperation,
    itemToDeleteId,
    itemToDeleteType,
}) => {
    const item = itemToDeleteType === 'zadost' ? 'žádost' : itemToDeleteType === 'dokumentace' ? 'dokumentace' : 'soubor';
    const isZadost = item === 'žádost' || item === 'dokumentace';

    const handleCancel = () => {
        setDeleteModalOpen(false);
    };

    const handleConfirm = () => {
        if (itemToDeleteId) {
            confirmOperation(itemToDeleteId);
            handleCancel();
        }
    };

    return (
        <Modal show={deleteModalOpen} onHide={() => handleCancel()} size='md' centered>
            <Modal.Header className='modal-header--radius modal-background' bsPrefix='d-flex justify-content-between align-items-center px-sm-5 modal-header-custom modal-background'>
                <Modal.Title bsPrefix='modal-title'>Opravdu smazat {item}?</Modal.Title>
            </Modal.Header>

            <Modal.Body className='modal-background' bsPrefix='px-sm-5 modal-body-custom d-flex flex-column'>
                <p className='pb-5 pridat-text label deletion'>{`Zvolen${isZadost ? 'á' : 'ý'} ${item} bude trvale odstraněn${isZadost ? 'a' : ''} a nelze ${isZadost ? 'ji' : 'ho'} obnovit.`}</p>
            </Modal.Body>

            <Modal.Footer className='modal-footer--radius modal-background' bsPrefix='d-flex px-sm-5 modal-footer-custom align-items-center justify-content-center'>
                <Row className='w-100 d-flex align-items-center justify-content-between'>
                    <button
                        className='modal-button modal-button--filled red mb-4 mb-lg-0'
                        onClick={() => handleConfirm()}
                    >
                        Smazat
                    </button>
                    <button
                        className='modal-button'
                        onClick={() => handleCancel(false)}
                    >
                        Zrušit
                    </button>
                </Row>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmBeforeDeleteModal;