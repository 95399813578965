import React, { useContext, useEffect, useState, useImperativeHandle, useMemo } from 'react';
import { Container, Row } from 'react-bootstrap';
import '../../css/zadostPovoleniStavby.css';
import '../../css/dokumentace.css';
import { AddProjektantInfoAndGenerateLink, DokumentaceRadioControls, FileUpload, UploadedAttachments } from '../index.js';
import { DEFAULT_DOCUMENTATION_FORMAT, DOCUMENT_FIFTH, DOCUMENT_FIRST, DOCUMENT_FOURTH, DOCUMENT_SECOND, DOCUMENT_THIRD_1, DOCUMENT_THIRD_2, DOCUMENT_THIRD_3, DOCUMENT_THIRD_4, DOCUMENT_THIRD_5} from '../../constants/sharedConstants.js';
import { shouldNotSave, spreadObject, filterPruvodniListDocuments, filterPruvodniListToDownload, handleFiles, handleSpreadUploadedDocuments, getOngoingUploads, getFormType, isPruvodniListNotYetSubmitted, hasDocumentaceControlsChanged, handlePlExceptions } from '../../helperFunctions/helpers.js';
import usePrevious from '../CustomHooks/usePrevious.jsx';
import { AuthContext } from 'react-oauth2-code-pkce';
import axios from 'axios';
import PropTypes from 'prop-types';
import { formSave, getFormById } from '../../apiCalls/formApiCalls.js';
import FormContext from '../../formContexts/FormContext.js';
import useFirstRender from '../CustomHooks/useFirstRender.jsx';
import { infoCircle, errorIcon, reloadIcon, closeErrorPanelRed, deleteFile } from '../../assets/index.js';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { isRequestOK } from '../../apiCalls/componentsApiCalls.js';
import usePrefetchSVGs from '../CustomHooks/usePrefetchSVGs.jsx';

const Dokumentace = ({
    urlPath,
    documents,
    setDocuments,
    isPlEmptyForUrednik = false,
    documentationFormRequest = null, // for urednik form is the whole request so using this prop.
}) => {
    const {
        intention,
        stepValue,
        saveFormRef,
        id,
        setUploadedBytes,
        setUploadError,
        uploadError,
        setWaitingForPruvodniList,
        isOfficer,
        validationRef,
        setStagesArr,
        waitingForPruvodniList,
    } = useContext(FormContext);
    const formType = getFormType(urlPath, true);
    const isException = formType === '14';
    const { versionId } = useParams();
    const isNewVersion = Boolean(versionId);
    const isExternalDocumentation = !isNewVersion && formType === 'documentation' && documentationFormRequest; // type for urednik
    const isRegularForm = Number(formType) > 0;
    const { token } = useContext(AuthContext);
    const [moreInfo, setMoreInfo] = useState(false);
    const { 
        buildApplication: buildApplicationFromDocumentation, 
        buildIntention: buildIntentionFromDocumentation,
        accompanyingDocument: accompanyingDocumentFromDocumentation 
    } = documentationFormRequest || {};
    const [request, setRequest] = useState(
        {
            'applicationId': id,
            'buildApplication': {
                ...(buildApplicationFromDocumentation ?
                    {...(buildApplicationFromDocumentation || {})} 
                    : {'documentations': [],
                        ...(!isException && {
                            shareForm: {
                                firstName: '',
                                lastName: '',
                                paperType: 'ID',
                                paperNumber: ''
                            },
                            authorizedPersonSharedLink: ''
                        })})
            },
            'buildIntention': {
                ...(buildIntentionFromDocumentation ? 
                    {...(buildIntentionFromDocumentation || {})} 
                    : {'documentUploadHere': null,
                        'documentPaperForm': null,
                        'documentPreviousVersion' : null,
                        ...(!isException && {
                            accompanyingDocumentPdf: null,
                            accompanyingDocumentDeveloper: null})})
            },
            'accompanyingDocument': {
                ...(accompanyingDocumentFromDocumentation ?
                    {...(accompanyingDocumentFromDocumentation || {})}
                    : {accompanyingDocumentStatus: ''})}
        }
    );
    const allDocuments = useMemo(() => Object.values(documents || {}).flat(), [documents]);
    const onGoingUploads = useMemo(() => getOngoingUploads(allDocuments), [allDocuments]);
    const prevRequest = usePrevious(request);
    const firstRender = useFirstRender();
    const documentsToUpdate = useMemo(() => allDocuments.filter(fileObj => (!fileObj.blobFile && !fileObj.abortController && !fileObj.message)), [allDocuments]);
    const existingFilesToUpdate = useMemo(() => documentsToUpdate.filter(el => el.existing), [documentsToUpdate]);
    const newFilesToUpdate = useMemo(() => documentsToUpdate.filter(el => !el.existing), [documentsToUpdate]);
    const buildIntention = request?.buildIntention;
    const buildApplication = request?.buildApplication;
    const {accompanyingDocumentPdf, accompanyingDocumentDeveloper, documentUploadHere, documentPaperForm, documentPreviousVersion} =  buildIntention || {};
    const refObjectPlFormat = { accompanyingDocumentPdf, accompanyingDocumentDeveloper };
    const prevRefObjectPlFormat = usePrevious(refObjectPlFormat);
    const refObjectDocumentationFormat = { documentUploadHere, documentPaperForm, documentPreviousVersion };
    const prevRefObjectDocumentFormat = usePrevious(refObjectDocumentationFormat);
    const isAllPlFormatRadioNull = useMemo(() => Object.values(prevRefObjectPlFormat || {}).every(el => el === null), [prevRefObjectPlFormat]);
    const isSavedFromDokumentaceRadioControls = useMemo(() => hasDocumentaceControlsChanged(prevRefObjectDocumentFormat, buildIntention), [prevRefObjectDocumentFormat]);
    const svgUrls = usePrefetchSVGs([reloadIcon, closeErrorPanelRed, deleteFile]); // {}
    const saveChanges = async (manualReq = null) => {
        const source = axios.CancelToken.source();
        // add accompanyingStatus to request to follow up on the status but do not send it
        // eslint-disable-next-line no-unused-vars
        const {accompanyingDocument, ...rest} = manualReq || request || {};
        const filteredRequest = {...rest};
        await formSave(filteredRequest, token, urlPath, source);
    };
    const isOngoingPlUpload = useMemo(() => onGoingUploads.some(el => el.section === DOCUMENT_FIRST), [onGoingUploads]);
    const isRegularFormInPaperFormat = isRegularForm && buildIntention?.documentPaperForm;
    const hasPreviousZamerPaperFormat = !!buildIntention?.eszId && isRegularFormInPaperFormat;
    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (isExternalDocumentation) {
            return;
        }
        const source = axios.CancelToken.source();
        if (id) {
            (async () => {
                const response = await getFormById(id, token, source, intention, null, pathname, navigate);

                if (isRequestOK(response?.status)) {
                    const loadedBuildIntention = response?.data?.buildIntention || {};
                    const loadedBuildApplication = response?.data?.buildApplication || {};
                    const loadedAccompanyingDocument = response?.data?.accompanyingDocument || {};
                    try {
                        setRequest(prev => ({
                            ...prev,
                            buildIntention: {...prev?.buildIntention,
                                ...spreadObject(loadedBuildIntention),
                                ...(formType !== 'documentation' && {
                                    documentUploadHere: !loadedBuildIntention?.eszId ?
                                        (loadedBuildIntention?.documentUploadHere ?? true)
                                        : (loadedBuildIntention?.eszId &&
                                        (loadedBuildIntention?.documentPreviousVersion === null || loadedBuildIntention?.documentPreviousVersion || loadedBuildIntention?.documentPaperForm))
                                            ? false
                                            : Boolean(loadedBuildIntention?.documentUploadHere),
                                    documentPaperForm: loadedBuildIntention?.documentPaperForm ?? false,
                                    ...(loadedBuildIntention?.eszId && { documentPreviousVersion: (loadedBuildIntention?.documentPreviousVersion ?? true) }),
                                }),
                                ...(formType === 'documentation' && DEFAULT_DOCUMENTATION_FORMAT),
                                accompanyingDocumentPdf: loadedBuildIntention?.documentPaperForm ? false : loadedBuildIntention?.accompanyingDocumentPdf ?? true,
                                accompanyingDocumentDeveloper: loadedBuildIntention?.documentPaperForm ? true : loadedBuildIntention?.accompanyingDocumentDeveloper ?? false,
                            },
                            buildApplication: {...prev?.buildApplication, 
                                authorizedPersonSharedLink: loadedBuildApplication?.authorizedPersonSharedLink ?? '', 
                                shareForm: {
                                    ...prev?.buildApplication?.shareForm, 
                                    ...loadedBuildApplication?.shareForm
                                }
                            },
                            accompanyingDocument: {...prev?.accompanyingDocument,
                                ...spreadObject(loadedAccompanyingDocument)
                            }
                        }));
                        const documentations = loadedBuildApplication?.documentations ?? []; // empty uploaded list in case of isNewVersionForRegularUser
                        if (setWaitingForPruvodniList) setWaitingForPruvodniList(isPruvodniListNotYetSubmitted(response?.data ?? {}, formType === 'documentation' ? 'documentation' : ''));
                        handleSpreadUploadedDocuments([...documentations, ...onGoingUploads], loadedBuildIntention, setDocuments, setUploadedBytes);              
                    } catch (error) {
                        console.log(error);
                    }
                } else {
                    console.log('ID NOT YET IN DB');
                }
            })();

            return () => {
                source.cancel('Operation canceled by the user.');
            };
        } else {
            console.log('NON EXISTENT ID');
        }
    }, []);
    
    useEffect(() => {
        if (
            shouldNotSave(prevRequest, request, firstRender) ||
            (!isNewVersion && !buildIntention?.id) ||
            isAllPlFormatRadioNull ||
            isOfficer ||
            isSavedFromDokumentaceRadioControls
        ) {
            return;
        }

        saveChanges();
    }, [buildApplication.documentations, buildIntention.accompanyingDocumentDeveloper, buildIntention.accompanyingDocumentPdf]);

    useEffect(() => {
        if (newFilesToUpdate.length >= 50000) {
            setUploadError(prev => [...prev, {
                where: null,
                why: 'total_amount',
                uid: null,
            }]);   
            return;
        } else if (newFilesToUpdate.length < 50000 && uploadError.find(el => el.why === 'total_amount')) {
            setUploadError(prev => [...prev].filter(el => el.why !== 'total_amount')); 
        }
        
        setRequest(prev => ({ ...prev, buildApplication: {
            ...prev.buildApplication,
            documentations: [...existingFilesToUpdate, ...newFilesToUpdate]
            // eslint-disable-next-line no-unused-vars
                .map(({ blobFile, message, abortController, existing, flagged, ...rest }) => rest)} 
        }));

        if (setWaitingForPruvodniList && buildIntention?.accompanyingDocumentPdf) {            
            const isPruvodniListSubmitted = [...existingFilesToUpdate, ...newFilesToUpdate]?.some(el => el.section === DOCUMENT_FIRST);
            console.log(isPruvodniListSubmitted);
            setWaitingForPruvodniList(!isPruvodniListSubmitted);
        }
    }, [existingFilesToUpdate.length, newFilesToUpdate.length]);
    
    const handleFileInput = (files, category) => {
        if (uploadError.find(el => el.why === 'total_amount')) {
            return;
        }
        handleFiles(files, category, token, setDocuments, 'documents', allDocuments);
    };

    useImperativeHandle(saveFormRef, () => ({
        saveForm: () => saveChanges()
    }));

    const uploadErr = React.useMemo(() => allDocuments.some(fileObj => (fileObj.message)), [allDocuments]);
    useImperativeHandle(validationRef, () => ({
        validate() {
            let err = false;

            if ((setWaitingForPruvodniList && handlePlExceptions(waitingForPruvodniList, formType) && formType !== 'documentation')
                || uploadErr)
            {
                err = true;
            }

            setStagesArr(prev => [...prev].map(stg => {
                if (Number(stg.stage) === Number(stepValue)) {
                    return {...stg, error: err };
                }
                return stg;
            }));

            return !err;
        }
    }));

    return (
        <Container className='d-flex row align-items-center' fluid>
            {stepValue && <Row className='row-wrapper'>
                <p className='p-0 pb-4 title primary'>{`${stepValue}. Dokumentace`}</p>
                <span className='dokumentace-text fst-italic p-0'>
                    {(formType === '02' || formType === '03' || formType === '16') ? 'Dokumentace pro povolení záměru podle vyhlášky o dokumentaci staveb'
                        : formType === '04' ? 'Dokumentace pro povolení stavby podle vyhlášky o dokumentaci staveb'
                            : formType === '06' ? 'Dokumentace pro povolení změny využití území podle vyhlášky o dokumentaci staveb'
                                : formType === '08' ? 'Dokumentace pro rámcové povolení podle vyhlášky o dokumentaci staveb'
                                    : formType === '09' ? 'Dokumentace pro povolení záměru podle vyhlášky o dokumentaci staveb s vyznačením zamýšlených změn'
                                        : formType === '11' ? 'Dokumentace pro povolení stavby s vyznačením odchylek oproti ověření projektové dokumentaci podle vyhlášky o dokumentaci staveb '
                                            : formType === '12' ? 'Dokumentace pro povolení stavby s vyznačením odchylek (došlo-li k nepodstatným odchylkám oproti ověřené projektové dokumentaci) podle vyhlášky o dokumentaci staveb'
                                                : formType === '14' ? 'Dokumentace stavby s vyznačením změn vyvolaných změnou v užívání oproti ověřené dokumentaci k povolené stavbě podle vyhlášky o dokumentaci staveb'
                                                    : formType === '15' ? 'Dokumentace pro odstranění stavby podle vyhlášky o dokumentaci staveb'
                                                        : formType === '17' ? 'Dokumentace podle vyhlášky o dokumentaci staveb'
                                                            : ''}
                </span>
            </Row>}
            <Row className='row-wrapper'>
                {isRegularForm && <DokumentaceRadioControls
                    documents={documents} 
                    setDocuments={setDocuments} 
                    setUploadedBytes={setUploadedBytes} 
                    saveChanges={saveChanges} 
                    setRequest={setRequest}
                    request={request}
                    hasInfo={true}
                />}
                <p className='p-0 dokumentace-help_text'><img src={infoCircle} alt="infoCircle" className='me-2'/>Datový limit dokumentace je 5 GB.</p>
            </Row>
            {!isException && <Container className='pb-5'>
                <Row className='pb-3'>
                    <p className='p-0 title secondary'>A — Průvodní list</p>
                </Row>
                <Row>
                    <span className='dokumentace-text fst-italic fst-italic--grey p-0'>
                        {!isOfficer ? 'Průvodní list musí zpracovat projektant nebo autorizovaná osoba.' : 'Zde nahrajte průvodní list ve formátu PDF.'} {!isOfficer && 'Zde můžete vytvořit odkaz pro vytvoření Průvodního listu, který projektantovi pošlete.'}
                    </span>
                    {isRegularFormInPaperFormat && <span className='dokumentace-text bold m-0 p-0 pt-3'>V případě listinného podání dokumentace se průvodní list se žádostí neodesílá, je třeba jej stáhnout a přiložit k listinné dokumentaci.</span>}
                </Row>
                {!isException && !isOfficer && !isRegularFormInPaperFormat && <Row className='pt-3'>
                    <label className='p-0 py-1 d-flex'>
                        <input
                            className='me-2'
                            type='radio'
                            checked={buildIntention?.accompanyingDocumentPdf ?? true}
                            disabled={buildIntention?.documentPaperForm}
                            onChange={() => {
                                setRequest(prev => ({...prev, 
                                    buildIntention: {...prev.buildIntention, accompanyingDocumentPdf: true, accompanyingDocumentDeveloper: false},
                                    buildApplication: {...prev.buildApplication,
                                        shareForm: {
                                            firstName: '',
                                            lastName: '',
                                            paperType: 'ID',
                                            paperNumber: ''
                                        },
                                        authorizedPersonSharedLink: '',
                                    }
                                }));
                                if (setWaitingForPruvodniList) setWaitingForPruvodniList(isPruvodniListNotYetSubmitted(request ?? {}, 'accompanyingDocumentPdf'));
                            }}
                        />
                        Nahrát průvodní list v pdf formátu
                    </label>
                    <label className='p-0 py-1 d-flex'>
                        <input
                            className='me-2'
                            type='radio'
                            checked={buildIntention?.accompanyingDocumentDeveloper ?? false}
                            disabled={buildIntention?.documentPaperForm}
                            onChange={() => {
                                setRequest(prev => ({...prev,
                                    buildIntention: {...prev.buildIntention, accompanyingDocumentPdf: false, accompanyingDocumentDeveloper: true},
                                }));
                                setDocuments(prev => ({
                                    ...prev,
                                    [DOCUMENT_FIRST]: [...(prev?.[DOCUMENT_FIRST] || [])].filter(el => el.subSection)
                                }));
                                if (setWaitingForPruvodniList) setWaitingForPruvodniList(isPruvodniListNotYetSubmitted(request ?? {}, 'accompanyingDocumentDeveloper'));
                            }}
                        />
                        Vytvořit odkaz pro projektanta pro vyplnění průvodního listu
                    </label>
                </Row>}
                {(!isException && (buildIntention?.accompanyingDocumentDeveloper || hasPreviousZamerPaperFormat)) && 
                <>
                    <AddProjektantInfoAndGenerateLink
                        urlPath={urlPath}
                        documentToDownload={filterPruvodniListToDownload(documents[DOCUMENT_FIRST])}
                        request={request}
                        setRequest={setRequest}
                    />
                    <>
                        {request?.buildApplication?.authorizedPersonSharedLink && (
                            <UploadedAttachments
                                category={DOCUMENT_FIRST}
                                attachments={filterPruvodniListDocuments(documents[DOCUMENT_FIRST])}
                                setUploads={setDocuments}
                            />
                        )}
                    </>
                </>}
                {((!isException && buildIntention?.accompanyingDocumentPdf && !hasPreviousZamerPaperFormat) || isOfficer) && 
                    <>
                        <UploadedAttachments
                            prefetchedSvg={svgUrls}
                            category={DOCUMENT_FIRST}
                            attachments={documents[DOCUMENT_FIRST]?.filter(el => !el.subSection)}
                            allDocuments={documents}
                            urlPath={urlPath}
                            setUploads={setDocuments}
                            tempRadioState
                        />                    
                        <FileUpload allUploads={allDocuments} handleFileInput={handleFileInput} category={DOCUMENT_FIRST}/>
                    </>
                }
                {/* setWaitingForPruvodniList function is undefined in non relevant forms */}
                {(((!!setWaitingForPruvodniList && handlePlExceptions(waitingForPruvodniList, formType)) || isPlEmptyForUrednik) && !isOngoingPlUpload) && 
                <Row className='pt-4' style={{color: '#C52A3A', fontSize: '1rem', marginBottom: '.5rem'}}>
                    <img src={errorIcon} alt='errorIcon' style={{padding: '0', marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                    {formType === 'documentation' ? 
                        'Dokumentaci bude možné vložit až bude nahrán průvodní list.'
                        : 'Bez průvodního listu nebude možné žádost odeslat.'}
                </Row>}
            </Container>}
            {!buildIntention?.documentPaperForm && <>
                {!isException && <>
                    <Container className='pb-5'>
                        <Row className='pb-3'>
                            <p className='p-0 title secondary'>B — Souhrnná technická zpráva</p>
                        </Row>
                        <Row>
                            <span className='dokumentace-text fst-italic p-0'>
                        Celkový popis území a stavby, Urbanistické a základní architektonické řešení, Základní technické a technologické řešení záměru,
                        Stavebně technické řešení a technologické řešení, Přístupnost stavby, Zásady bezpečnosti při užívání stavby, Základní technický popis stavby,
                        Základní popis technických a technologických zařízení, Zásady požární bezpečnosti
                                {!moreInfo && <span className='dokumentace-text--more' onClick={() => setMoreInfo(!moreInfo)}>... zobrazit celé</span>}
                                {moreInfo && <>, Úspora energie a tepelná ochrana, Hygienické požadavky na stavbu, požadavky na pracovní a komunální prostředí,
                            Zásady ochrany stavby před negativními účinky vnějšího prostředí, Připojení na technickou infrastrukturu,
                            Dopravní řešení, Řešení vegetace a souvisejících terénních úprav, Popis vlivů stavby na životní prostředí a jeho ochrana, 
                            Celkové vodohospodářské řešení, Ochrana obyvatelstva, Zásady organizace výstavby.
                                </>}
                            </span>
                        </Row>
                        <UploadedAttachments
                            prefetchedSvg={svgUrls}
                            category={DOCUMENT_SECOND}
                            attachments={documents[DOCUMENT_SECOND]}
                            allDocuments={documents}
                            urlPath={urlPath}
                            setUploads={setDocuments}
                        />                    
                        <FileUpload allUploads={allDocuments} handleFileInput={handleFileInput} category={DOCUMENT_SECOND}/>
                    </Container>
                    <Container className='pb-5'>
                        <Row className='pb-3'>
                            <p className='p-0 title secondary'>C — Situační výkresy</p>
                        </Row>
                        <Row className='pb-4'>
                            <span className='dokumentace-text fst-italic p-0'>Situační výkres širších vztahů, Katastrální situační výkres, Koordinační situační výkres, Speciální situační výkresy, Dělení a scelení pozemků</span>
                        </Row>
                        <Row className='py-4'>
                            <Row className='pb-3'>
                                <p className='p-0 title secondary fw-normal'>C1 - Situační výkres širších vztahů</p>
                            </Row>
                            <UploadedAttachments
                                prefetchedSvg={svgUrls}
                                category={DOCUMENT_THIRD_1}
                                attachments={documents[DOCUMENT_THIRD_1]}
                                allDocuments={documents}
                                urlPath={urlPath}
                                setUploads={setDocuments}
                            />                    
                            <FileUpload allUploads={allDocuments} handleFileInput={handleFileInput} category={DOCUMENT_THIRD_1}/>
                        </Row>
                        <Row className='py-4'>
                            <Row className='pb-3'>
                                <p className='p-0 title secondary fw-normal'>C2 - Katastrální situační výkres</p>
                            </Row>
                            <UploadedAttachments
                                prefetchedSvg={svgUrls}
                                category={DOCUMENT_THIRD_2}
                                attachments={documents[DOCUMENT_THIRD_2]}
                                allDocuments={documents}
                                urlPath={urlPath}
                                setUploads={setDocuments}
                            />                    
                            <FileUpload allUploads={allDocuments} handleFileInput={handleFileInput} category={DOCUMENT_THIRD_2}/>
                        </Row>
                        <Row className='py-4'>
                            <Row className='pb-3'>
                                <p className='p-0 title secondary fw-normal'>C3 - Koordinační situační výkres</p>
                            </Row>
                            <UploadedAttachments
                                prefetchedSvg={svgUrls}
                                category={DOCUMENT_THIRD_3}
                                attachments={documents[DOCUMENT_THIRD_3]}
                                allDocuments={documents}
                                urlPath={urlPath}
                                setUploads={setDocuments}
                            />                    
                            <FileUpload allUploads={allDocuments} handleFileInput={handleFileInput} category={DOCUMENT_THIRD_3}/>
                        </Row>
                        <Row className='py-4'>
                            <Row className='pb-3'>
                                <p className='p-0 title secondary fw-normal'>C4 - Speciální výkresy</p>
                            </Row>
                            <UploadedAttachments
                                prefetchedSvg={svgUrls}
                                category={DOCUMENT_THIRD_4}
                                attachments={documents[DOCUMENT_THIRD_4]}
                                allDocuments={documents}
                                urlPath={urlPath}
                                setUploads={setDocuments}
                            />                    
                            <FileUpload allUploads={allDocuments} handleFileInput={handleFileInput} category={DOCUMENT_THIRD_4}/>
                        </Row>
                        <Row className='py-4'>
                            <Row className='pb-3'>
                                <p className='p-0 title secondary fw-normal'>C5 - Dělení nebo scelení pozemků</p>
                            </Row>
                            <UploadedAttachments
                                prefetchedSvg={svgUrls}
                                category={DOCUMENT_THIRD_5}
                                attachments={documents[DOCUMENT_THIRD_5]}
                                allDocuments={documents}
                                urlPath={urlPath}
                                setUploads={setDocuments}
                            />                    
                            <FileUpload allUploads={allDocuments} handleFileInput={handleFileInput} category={DOCUMENT_THIRD_5}/>
                        </Row>
                    </Container>
                </>}
                <Container className='pb-5'>
                    <Row className='pb-3'>
                        <p className='p-0 title secondary'>D — Dokumentace objektů</p>
                    </Row>
                    <Row>
                        <span className='dokumentace-text fst-italic fst-italic--grey p-0'>Dokumentace se zpracovává po objektech a souborech technických nebo technologických zařízení. Dokumentace každého objektu se zpracovává přiměřeně podle požadavků stanovených v dokumentaci pro povolení záměru příslušného objektu.</span>
                    </Row>
                    <UploadedAttachments
                        prefetchedSvg={svgUrls}
                        category={DOCUMENT_FOURTH}
                        attachments={documents[DOCUMENT_FOURTH]}
                        allDocuments={documents}
                        urlPath={urlPath}
                        setUploads={setDocuments}
                    />                    
                    <FileUpload allUploads={allDocuments} handleFileInput={handleFileInput} category={DOCUMENT_FOURTH}/>
                </Container>
                {!isException && <Container className='mb-1'>
                    <Row className='pb-3'>
                        <p className='p-0 title secondary'>E — Dokladová část</p>
                    </Row>
                    <Row>
                        <span className='dokumentace-text fst-italic fst-italic--grey p-0'>
                        Závazná stanoviska, stanoviska, rozhodnutí, vyjádření dotčených orgánů
                            <br />Doklad podle jiného právního předpisu
                            <br /> Vyjádření vlastníků veřejné dopravní a technické infrastruktury
                        </span>
                    </Row>
                    <UploadedAttachments
                        prefetchedSvg={svgUrls}
                        category={DOCUMENT_FIFTH}
                        attachments={documents[DOCUMENT_FIFTH]}
                        allDocuments={documents}
                        urlPath={urlPath}
                        setUploads={setDocuments}
                    />                    
                    <FileUpload allUploads={allDocuments} handleFileInput={handleFileInput} category={DOCUMENT_FIFTH}/>
                </Container>}
            </>}
        </Container>
    );
};

Dokumentace.propTypes = {
    urlPath: PropTypes.string.isRequired,
    documents: PropTypes.object,
    setDocuments: PropTypes.func,
    externalaccompanyingDocumentStatus: PropTypes.any,
    documentationFormRequest: PropTypes.any,
    isOfficer: PropTypes.bool,
};

export default Dokumentace;
